import { render, staticRenderFns } from "./SiteRegulation.vue?vue&type=template&id=8294d636&scoped=true&"
import script from "./SiteRegulation.vue?vue&type=script&lang=js&"
export * from "./SiteRegulation.vue?vue&type=script&lang=js&"
import style0 from "./SiteRegulation.vue?vue&type=style&index=0&module=true&lang=css&"
import style1 from "./SiteRegulation.vue?vue&type=style&index=1&id=8294d636&language=css&scoped=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8294d636",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardActions,VIcon,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
