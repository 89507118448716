<template>
<div :class="$style.topics">

    <div :class="$style.header">
        <div :class="$style.title">
            <str :index="'topics > title'"/>
        </div>

        <div :class="$style.sub_title">
            <str :index="'topics > sub_title'"/>
        </div>

        <div :class="$style.legend">
            <div :class="$style.legend_item">
                <str :index="'topics > legend > ppt'"/>
                <v-img :src="previewIcon('preview')" width="16" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
            </div>
            <span>|</span>
            <div :class="$style.legend_item">
                <str :index="'topics > legend > ppt > download'"/>
                <v-img :src="previewIcon('download')" width="16" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
            </div>
            <span>|</span>
            <div :class="$style.legend_item">
                <str :index="'topics > legend > video'"/>
                <v-img :src="previewIcon('video_preview')" width="16" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
            </div>
        </div>
        
        <div><str :index="'topics > legend > note'"/></div>
    </div>

    <v-container>
        <v-row>
            <v-col v-for="(topic, topic_index) in program.topics" :key="'pid_'+program.id+'_'+topic_index" cols="12" lg="6" md="12" style="padding: 0 0 0 12px" v-show="topic.display === 'Y'">
                <div :class="$style.topic_wrapper">
                    <div :class="$vuetify.breakpoint.mdAndUp ? $style.topicNameWide : $style.topicNameNarrow" style="display:inline;">{{topic.name[language]}}</div>
                    <div style="display:inline; width:10%;">
                        <v-btn v-if="topic.files.preview[language]" @click="preview(program.id, topic_index, 'preview', language, topic.files.preview[language])" text>
                            <v-img :src="previewIcon('preview')" width="26" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
                        </v-btn>
                    </div>
                    <div style="display:inline; width:10%;">
                        <v-btn v-if="topic.files.ppt[language]" @click="download(program.id, topic_index, 'ppt', language, topic.files.ppt[language])" text>
                            <v-img :src="previewIcon('download')" width="22" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
                        </v-btn>
                    </div>
                    <div style="display:inline; width:10%;">
                        <v-btn v-if="topic.files.video[language]" @click="play(program.id, topic_index, 'video', language, topic.files.video[language])" text>
                            <v-img :src="previewIcon('video_preview')" width="24" contain class="d-inline-flex mx-2" transition="scale-transition" style="cursor: pointer;"/>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="popups.preview" width="75%">
        <v-card dark>
            <v-card-title class="justify-center">
                <str :index="'topics > legend > ppt'"/>
                <div :class="$style.closeIconWrapper" @click="popups.preview=false; popups.preview_topic_index=null;"><v-icon style="font-size:30px">mdi-close-circle</v-icon></div>
            </v-card-title>
            <v-card-text>
                <vueper-slides v-if="popups.preview && slides" :bullets="true">
                    <vueper-slide v-for="(slide, i) in slides" :key="'preview_'+i" :image="slide" style="background-size:container;"/>
                </vueper-slides>
            </v-card-text>
            <v-card-actions class="justify-center" style="background: var(--v-brandWhite-base)">
                <v-btn color="var(--v-brandOrange-base)" 
                    @click="download(program.id, popups.preview_topic_index, 'ppt', language, program.topics[popups.preview_topic_index].files.ppt[language])">
                    <str :index="'button > download'"/>
                </v-btn>
            </v-card-actions>            
        </v-card>
    </v-dialog>

    <v-dialog v-model="popups.video" width="75%" max-width="960">
        <v-card dark>
            <v-card-title class="justify-center">
                {{videoFile()}}
                <div :class="$style.closeIconWrapper" @click="popups.video=false"><v-icon style="font-size:30px">mdi-close-circle</v-icon></div>                
            </v-card-title>
            <v-card-text class="py-0">
                <div style="text-align: center;width: 100%; position: relative;">
                    <VideoPlayer v-if="popups.video" :video="video"/>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center" style="background: var(--v-brandWhite-base)">
                <v-btn color="var(--v-brandOrange-base)" @click="popups.video=false"><str :index="'button > close'"/></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog id="popup-download-acknowledge" v-model="popups.download_acknowledgement" persistent no-click-animation :fullscreen="windowSize().x <= 960 || windowSize().y <= 630" width='525' overlay-opacity="0.11">
        
        <v-card class="rounded-md" style="border: 1px solid var(--v-background-darken1);">
            <v-icon @click="popups.download_acknowledgement=false;" class="acknowledgeCloseButton">mdi-close-circle</v-icon>                       
            <v-card-title class="justify-center brandBlue--text pt-13 pb-1">
                <str :index="'title > acknowledgement'"/>
            </v-card-title>
            <v-row no-gutters class="dark">
                <v-col cols="4" style="border-bottom: 1px solid #bababa;"></v-col>
                <v-col cols="4" style="border-bottom: 2px solid var(--v-brandBlue-base);"></v-col>
                <v-col cols="4" style="border-bottom: 1px solid #bababa;"></v-col>
            </v-row>
            <v-row justify="center" no-gutters style="height:140px; border-bottom: 1px solid #bababa;">
                <v-card-text class="brandBlack--text pt-8 text-center" style="width:90%; font-weight: 600;">
                    <str :index="'message > download acknowledgement'"/>
                </v-card-text>
            </v-row>
            <v-card-actions class="justify-space-around" style="height:60px">
                <v-spacer/>
                <v-btn small dark color="var(--v-brandBlueCTA-base)" :class="$style.acknowledgeButton" 
                    @click="popups.download_acknowledgement=false;$store.dispatch('pending_download_request_details', null);">
                <str :index="'button > later'"/></v-btn>
                <v-spacer/>
                <v-btn small dark color="var(--v-brandOrange-base)" :class="$style.acknowledgeButton" @click="saveDownloadAcknowledgement();"><str :index="'button > i acknowledge'"/></v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>


</div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import PreviewIcon from '@/assets/icons/preview.png'
import DownloadIcon from '@/assets/icons/download.png'
import VideoPreviewIcon from '@/assets/icons/video_preview.png'

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        program: {
            type: Object,
            required: true
        }
    },
    components: {
        VideoPlayer,
        VueperSlides,
        VueperSlide
    },
    created: function(){
        if (this.$store.getters.pending_download_request_details===undefined){
            location.reload();
        }
        else if (this.$store.getters.pending_download_request_details!==null){
            let pending_download_request_details = this.$store.getters.pending_download_request_details;
            this.download(pending_download_request_details.program_id, pending_download_request_details.topic_index, pending_download_request_details.type, pending_download_request_details.language, pending_download_request_details.files)            
        }
    },
    data: function(){
        return {
            popups: {
                preview: false,
                preview_topic_index: null,
                video: false,
                download_acknowledgement: false
            },
            slides: null,
            video: null
        }
    },
    methods: { 
        setPendingDownloadRequestDetails: function(program_id=null, topic_index=null, type=null, language=null, file=null){
                let pending_download_request_details = {
                    program_id: program_id,
                    topic_index: topic_index,
                    type: type,
                    language: language,
                    files: file
                };
                if (this.popups.download_acknowledgement){
                    this.$store.dispatch('pending_download_request_details', pending_download_request_details);
                }
        },
        saveDownloadAcknowledgement: function(){
            let component = this;
            return new Promise((resolve, reject)=>{
                let action = 'forms';
                let call = 'post_profile_download_acknowledgement';
                if(component.sendRequest){                  
                    component.sendRequest({
                        action: action,
                        call: call,
                        user_id: component.$store.getters.user.id
                    }).then(function(response){
                        if (response.data[action][call].result===true){
                            component.$controller.service.send('jwt.refresh');
                            component.popups.download_acknowledgement = false;
                            resolve();
                        }else{
                            component.popups.download_acknowledgement = false;
                            reject();
                        }
                    },function(){
                        component.popups.download_acknowledgement = false;
                        reject();
                    });
                }else{
                    component.popups.download_acknowledgement = false;
                    resolve(component.popups.download_acknowledgement);
                }
            });      
        },
        videoFile: function(){
            return this.video ? this.video['video'] : null;
        },
        previewIcon: function(identifier){
            switch(identifier){
                case "preview":
                    return PreviewIcon;

                case "download":
                    return DownloadIcon;

                case "video_preview":
                    return VideoPreviewIcon;
            }
        },
        play: function(program_id, topic_index, type, language, files){
            let self = this;
            self.video = {
                language: language,
                pid: program_id,
                uid: self.$store.getters.user.id,
                tin: topic_index,
                video: files[0]
            }
        
            self.popups.video = true;
        },
        preview: function(program_id, topic_index, type, language, files){
            let self = this;
            self.slides = [];

            let promises = [];

            for(let f=0; f<files.length; f++){
                let file = files[f];

                promises.push(new Promise((resolve, reject)=>{
                    self.streamFile({
                        params: {
                            program_id: program_id,
                            topic_index: topic_index,
                            language: language,
                            filename: file,
                            type: type,
                            request: 'serve_file',
                        },
                        filename: file
                    }).then(function(response){
                        self.slides.push(response);
                        resolve();
                    },function(){
                        reject();
                    })
                }))
            }

            Promise.all(promises).then(function(){
                self.popups.preview = true;
                self.popups.preview_topic_index = topic_index;
            })
            
        },
        download: function(program_id, topic_index, type, language, files){
            let self = this;
            if (this.$store.getters.user.download_acknowledgement===undefined || this.$store.getters.user.download_acknowledgement==='0000-00-00 00:00:00'){
                this.popups.download_acknowledgement = true;
                return this.setPendingDownloadRequestDetails(program_id, topic_index, type, language, files);
            }
            
            if (this.$store.getters.user.download_acknowledgement!==undefined && this.$store.getters.user.download_acknowledgement!=='0000-00-00 00:00:00'){
                this.$store.dispatch('pending_download_request_details', null);               
                self.downloadFile({
                    params: {
                        program_id: program_id,
                        topic_index: topic_index,
                        language: language,
                        filename: files[0],
                        type: type,
                        request: 'get_file',
                    },
                    filename: files[0]
                });
            }            
        }
    },
    computed: {
        popups_preview: function(){
            return this.popups.preview;
        }
    },
    watch: {
        popups_preview: function(after, before){
            if (after===false && after!==before){
                this.popups.preview_topic_index = null;
            }
        }
    }
}
</script>

<style module>
.closeIconWrapper {
    position: absolute;
    top: 10px;
    right: 15px;
    color: black;
    cursor: pointer;
    z-index: 1000;
}

.topicNameWide {
    width: 70%;
}

.topicNameNarrow {
    width: 62%;
}

.topics {
    padding-top: 15px;
}

.header{
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}

.title{
    color:  var(--v-brandOrangeAccent2-base);
    font-size: 1.5em;
    font-weight: 600;
    margin: 10px;
}

.sub_title{

}

.legend_item{
    display: inline-flex;
    margin-top: 15px;
    margin-right: 10px;
    margin-bottom: 8px;
    margin-left: 15px;
}

.legend_item i{
    margin: 5px;
}

.topic_wrapper {
    border-bottom: 1px solid var(--v-brandGray3-base);
    display:inline-flex;
    width:100%;
    align-items:center;
    height:60px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.topics table td{
    width: 10%;
    text-align: center;
}
.topics table td:first-child{
    width: 70%;
    text-align: left;
}

.video_popup {
    margin: 0 !important;
}

.acknowledgeButton {
    text-transform: initial;
    font-style: normal;
    font-size: 15px !important;
    width: 115px;
}
</style>

<style lang="css" scoped>
/deep/ .acknowledgeCloseButton {
    color: var(--v-brandBlack-base);
    font-size:22px;
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>

<style type="text/css">
.vueperslide{
    background-size: contain !important;
}
.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {background-color: #42b983;}

.vueperslides__bullet span {
  display: block !important;
  position: relative;
  top: -2px;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}

.vueperslides__arrow {
    background: var(--v-brandGray1-base) !important;
    color: var(--v-brandWhite-base) !important;
}

.v-dialog {
    margin: 0;
}
</style>