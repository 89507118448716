<template>
<span>
    <template v-if="library">
        <span @mousedown="longPress(true,edit)" @mouseup="longPress(false)" v-html="label" ref="strRefs"/> 
            <!-- {{language_override}} -->
        <v-dialog v-model="editing" v-if="text">
            <v-card>
                <v-card-title>EDITING: {{index}} <v-btn fab small @click="getLibrary"><v-icon>fa fa-sync-alt</v-icon></v-btn></v-card-title>

                <v-card-text>
                    <v-form 
                        ref="loginForm" 
                        v-model="validForm" 
                        v-on:submit.prevent="save"
                        >

                        <template v-for="language in languages">
                            <div :class="$style.input_container" :key="label+'_'+language">
                                <v-textarea outlined type="text" :label="label + ':' +language" v-model="text[language]" />
                                [preview]
                                <div :class="$style.preview" v-html="text[language]"/>
                            </div>
                        </template>


                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="editing=false">Close</v-btn> | <v-btn @click="save">SAVE</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>
    <template v-else>
        
    </template>
</span>
</template>

<script>
export default {
    name: 'str',
    created: function(){
        this.init();
    },
    props: {
        language_override: {
            type: String,
            required: false,
            default: null
        },
        index: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            mousedown: false,
            longPressTimer: null,
            validForm: false,
            editing: false,
            text: null,
            action: this.$options.name
        }
    },
    mounted: function() {
        if(this.index === "faq > answer idea 1" || this.index === "faq > answer idea 2" || this.index === "faq > answer program 3"  || this.index === "faq > noResult contact" ) {
            this.$nextTick(function() {
                this.faqGetSupport();
            });
        }
    },
    methods: {
        init: function(){        
            var text = this.source ? this.unlink(this.source) : {};
            for(let i=0; i<this.languages.length; i++){
                var language = this.languages[i];
                if(!text[language]){
                    text[language] = null;
                }
            }
            this.text = text;
        },
        getLibrary: function(){
            var self = this;   
            var action = this.action;
            var call = 'library';
            self.sendRequest({
                action: action,
                call: call
            }).then((response)=>{
                self.$store.dispatch('library',response.data[action][call]);
                self.init();
            })
        },
        longPress: function(state, command){
            if (this.$store.getters.user && (this.$store.getters.user.type==='admin' || this.$store.getters.user.type==='staff')) {
                this.mousedown = state;
                var self = this;
                clearTimeout(this.longPressTimer);
                this.longPressTimer = setTimeout(function(){
                    if(self.mousedown && typeof command=='function'){
                        alert('EDITING: '+self.index);
                        command();
                    }
                },2000);
            }
        },
        edit: function(){
            if(this.$store.getters.user.type === 'admin') {
                this.editing = true;
            }
        },
        save: function(){
            var self = this;   
            var action = this.action;
            var call = 'save';
            self.sendRequest({
                action: action,
                call: call,
                index: this.index,
                text: this.text
            }).then(()=>{
                self.getLibrary();
            })
        },
        // This function is for opening the dialog - Contact Us in the faq section by 
        // Look up the $refs that assign in span
        // Adding a class name in the v-html content
        // Add a event listener
        faqGetSupport: function() {
            // console.log(this.$refs['strRefs'].getElementsByClassName('faqGetSupport'));
            var self = this;
            this.$refs['strRefs'].getElementsByClassName('faqGetSupport')[0].addEventListener('click', function(event) {
                event.preventDefault();
                self.$controller.view.contactUs.open();
            })
        }
    },
    computed: {
        library: function(){
            return this.$store.getters.library ? this.$store.getters.library : false;
        },
        label: function(){
            var index = this.index;
            var language = this.currentLanguage;
            var source = this.source;

            if(source){
                return source[language] ? source[language] : '[MISSING Language: '+language+']';
            }else{
                return '[ERROR: "'+index+'" - does not exist]';
            }

            
        },
        source: function(){
            var index = this.index;
            return this.library ? this.library.labels[index] : null;
        },
        languages: function(){
            var languages = [];
            for(let i in this.library.languages){
                languages.push(i);
            }

            return languages;
        },
        currentLanguage: function(){
            return this.language_override ? this.language_override : this.$store.getters.language;
        }
    }

}
</script>

<style module>
.input_container{
    display: inline-block;
    vertical-align: top;
    width: 25%;
    min-width: 150px;
    margin-bottom: 15px;
}

.preview{
     padding: 15px; 
     border: 1px solid grey;
     min-height: 200px;
     width: 100%;
     color: darkslategrey;
}
</style>