<template>
<div>
    <v-img
        alt="Support Icon"
        class="shrink mr-2"
        contain
        :src="supportIcon"
        transition="scale-transition"
        width="auto"
        style="cursor: pointer;"
        @click="trigger=!trigger"
    />

    <ContactUs :trigger="trigger" @close="trigger=false;"/>
</div>
</template>

<script>
import ContactUs from '@/components/ContactUs/ContactUs.vue'
import SupportImg from '@/assets/icons/support.png'

export default {
    props: {
        navTrigger: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ContactUs
    },
    data: function(){
        return {
            trigger: false
        }
    },
    computed: {
        supportIcon: function (){
            return SupportImg;
        }        
    },
    watch: {
        navTrigger: function(after, before){
            if (after===true && after!==before){
                this.trigger=after;
                this.$emit("triggered")
            }
        }
    }
}
</script>

<style>

</style>