<template>
<div>
<v-dialog 
    v-model="display"
    :persistent="true"
    content-class="editProfileDialog"
    width='800'
    hide-overlay
    no-click-animation>
    <v-card :class="$style.contactUsCard" :style="`background-image: URL('${baseURL()}/src/assets/images/contact_us_bkg.png')`">
        <div :class="$style.closeBtnWrapper">
            <v-icon @click="display=false;$emit('close');" style="font-size:30px">
                mdi-close-circle-outline
            </v-icon>
        </div>
        <div :class="$style.contactUsTitleWrapper">
            <h3 :class="[$style.h3Heading, {[$style.h3HeadingMobile]:$vuetify.breakpoint.xsOnly}]">
                <str index="contact_us > title" />
            </h3>
            <p style="padding:10px; text-align:center;">
                <str index="contact_us > subTitle" />
            </p>
        </div>

        <v-form ref='contactUsForm' v-model="contactUsForm">
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form subject" />
                        </p>
                        <v-select
                            :items="subject_list"
                            outlined
                            dense
                            v-model="subject_data.subject"
                            :rules="formRules().validProgram"
                            >
                            <template v-slot:message="{ message }">
                                <str :index="message" />
                            </template>

                            <template slot="selection" slot-scope="data">
                                <str :index="data.item.text" />
                            </template>

                            <v-list-item-content slot="item" slot-scope="data">
                                <str :index="data.item.text" />
                            </v-list-item-content>
                        </v-select>
                    </v-col>
                    

                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form title" />
                        </p>
                        <v-select
                            :items="subject_title"
                            outlined
                            dense
                            :rules="formRules().validTitle"
                            v-model="subject_data.title">
                            <template v-slot:message="{ message }">
                                <str :index="message" />
                            </template>

                            <template slot="selection" slot-scope="data" dense :class="['contactUsInput']">
                                <!-- HTML that describe how select should render selected items -->
                                <str :index="data.item.text" />
                            </template>

                            <v-list-item-content slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                <v-list-item-title>
                                    <str :index="data.item.text" />
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-select>
                    </v-col>

                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form firstname" />
                        </p>
                        <v-text-field
                            outlined 
                            dense
                            v-model='subject_data.firstname' 
                            validate-on-blur
                            :rules="formRules().validFirstName">
                            <template v-slot:message="{ message }">
                                <str :index="message" />
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form lastname" />
                        </p>
                        <v-text-field 
                            outlined 
                            dense
                            v-model='subject_data.lastname' 
                            validate-on-blur
                            :rules="formRules().validLastName">
                            <template v-slot:message="{ message }">
                                <str :index="message" />
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form email" />
                        </p>
                        <v-text-field 
                            outlined 
                            dense
                            v-model='subject_data.email' 
                            validate-on-blur 
                            :rules="formRules().validEmail">
                            <template v-slot:message="{ message }">
                                <str :index="message" />
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="11" class='py-0'>
                        <p :class="$style.contactUsLabel">
                            <str index="contact_us > form message" />
                        </p>
                        <div>
                            <v-textarea 
                                v-model="subject_data.message" 
                                outlined 
                                required
                                :rules="formRules().validMessage">
                                <template v-slot:message="{ message }">
                                    <str :index="message" />
                                </template>
                            </v-textarea>
                        </div>                      
                    </v-col>

                    <v-col cols="11" class='py-0'>
                        <div style="text-align: center;">
                            <v-btn 
                                depressed
                                color="var(--v-brandOrange-base)" 
                                @click="sendContactUs()"
                                dark>
                                <str index="button > submit" />
                            </v-btn>

                            <p :class="['mt-1', $style.supportLabel]">
                                <template v-if="language=='fr'">
                                Ou encore, vous pouvez nous écrire à <a :href="'mailto:'+$support.email">{{$support.email}}</a><br>ou nous téléphoner au {{$support.tel}}, poste {{$support.ext}}.
                                </template>
                                <template v-else>
                                Or contact <a :href="'mailto:'+$support.email">{{$support.email}}</a> or {{$support.tel}} ext: {{$support.ext}}     
                                </template>
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.display" left :timeout="-1">
    <span v-html="snackbar.message"/>
    <v-btn
        dark
        text
        @click="snackbar.display=false"
      >
        <str index="button > close" /> 
    </v-btn>
</v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    props: {
        trigger: {
            type: Boolean,
            required: true
        }
    },
  created: function(){
    var component = this;

    const contactUs = new function() {
        this.sendContactUs = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){
                    component.sendRequest({
                        action: 'contact_us',
                        call: 'sendContactUs',
                        subject_data: context.contact_us.subject_data
                    }).then(function(response){
                        resolve(response.data.contact_us.sendContactUs);
                    },function(response){
                        reject(response.data.contact_us.sendContactUs)
                    })
                }else{
                  setTimeout(function(){
                      resolve();
                  },500)
                }
            })
        }
    }

    let contactUsConfig = {
        id: 'contact_us',
        context: {
            contact_us: {},
        },
        initial: 'ready',
        states: {
            ready: {
                on: {
                    contact_us_active: '#contact_us.active'
                }
            },
            active: {
                initial: 'waiting',
                states: {
                    waiting: {},
                    sending: {}
                },
                on: {
                    contact_us_close: '#contact_us.ready',
                    contact_us_send: '#contact_us.send_contact_us'
                }
            },
            send_contact_us: {
                invoke: {
                    src: (context) => new Promise((resolve, reject)=> {
                        contactUs.sendContactUs(component, context).then(function(response) {                            
                            component.snackbar.message = response.result === true ? component.labelTerm('contact_us > response subtitle sent_successfully')
                                : component.snackbar.message = component.labelTerm('message > system_failure generic');
                            component.snackbar.display = true;
                            resolve();
                        }, function() {
                            component.snackbar.message = component.labelTerm('message > system_failure generic');
                            component.snackbar.display = true;
                            reject();
                        })
                    }),
                    onDone: 'contact_us_close'
                }
            },
            contact_us_close: {
                invoke: {
                    src: () => new Promise(()=> {
                        component.$emit("close");
                    })
                }                
            }
        }
    }

    const machine = Machine(contactUsConfig,{
        guards: {
            allow_comms: function(context){
                return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
            },
            allow_login: function(context){
                return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
            },
            allow_logout: function(context){
                return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
            }
        }
    });


    this.service = interpret(machine)
    this.state = machine.initialState
    this.context = machine.context

    var self = this
    self.service.onTransition(state => {
        self.state = state.value;
        self.context = state.context
    }).start();

    self.init();
  },
    mounted: function(){
        this.init();
    },
    data: function(){
        return {
            service: null,
            state: null,
            context: null,            
            snackbar: {
                display: false,
                message: null
            },
            display: false,
            subject_data :{
                subject: null,
                firstname: null,
                lastname: null,
                title: null,
                message: null,
                email: null
            },
            contactUsForm: false
        }
    },
    methods: {
        init: function(){
            this.display = this.trigger===true;
            if(this.user) {
                this.subject_data.firstname = this.user.firstname;
                this.subject_data.lastname = this.user.lastname;
                this.subject_data.email = this.user.email;
                this.subject_data.subject = null;
                this.subject_data.title = null;
                this.subject_data.message = null;
            } else {
                this.subject_data.firstname = null;
                this.subject_data.lastname = null;
                this.subject_data.email = null;
                this.subject_data.subject = null;
                this.subject_data.title = null;
                this.subject_data.message = null;
            }
            if(this.display){
                this.service.send('contact_us_active')
            }
        },
        sendContactUs: function() {
            if(this.$refs.contactUsForm.validate()) {
                this.subject_data.language = this.$store.getters.language;
                this.context.contact_us.subject_data = {...this.subject_data};
                this.service.send('contact_us_send');
            }
        }
    },
    computed: {
        subject_list: function () {
            return [{
                    text: "contact_us > subject content_issue",
                    value: this.$store.getters.library ? this.$store.getters.library.labels["contact_us > subject content_issue"][this.language] : "Inappropriate"
                },
                {
                    text: "contact_us > subject general_feedback",
                    value: this.$store.getters.library ? this.$store.getters.library.labels["contact_us > subject general_feedback"][this.language] : "Feedback"
                },
                {
                    text: "contact_us > subject platform_issue",
                    value: this.$store.getters.library ? this.$store.getters.library.labels["contact_us > subject platform_issue"][this.language] : "Platorm Issue"
                },
                {
                    text: "contact_us > subject other",
                    value: this.$store.getters.library ? this.$store.getters.library.labels["contact_us > subject other"][this.language] : 'Other'
                }
            ];
        },
        subject_title: function () {
            if (this.language == 'en') {
                return [{
                        text: "contact_us > subject_title mr",
                        value: "mr"
                    },
                    {
                        text: "contact_us > subject_title mrs",
                        value: "mrs"
                    },
                    {
                        text: "contact_us > subject_title miss",
                        value: "miss"
                    },
                    {
                        text: "contact_us > subject_title ms",
                        value: "ms"
                    },
                    {
                        text: "contact_us > subject_title dr",
                        value: "dr"
                    }
                ];
            } else {
                return [{
                        text: "contact_us > subject_title mr",
                        value: "mr"
                    },
                    {
                        text: "contact_us > subject_title mrs",
                        value: "mrs"
                    },
                    {
                        text: "contact_us > subject_title miss",
                        value: "miss"
                    },
                    {
                        text: "contact_us > subject_title ms",
                        value: "ms"
                    },
                    {
                        text: "contact_us > subject_title dr",
                        value: "dr"
                    },
                    {
                        text: "contact_us > subject_title dre", 
                        value: "dre"
                    }
                ];
            }
        },
        language: function() {
            return this.$store.getters.language;
        },
        user: function() {
            return this.$store.getters.user;
        }, 
        message: function() {
            return this.$store.getters.message;
        },
        $support: function(){
            return this.$store.getters.library.support;
        }
    },
    watch: {
        trigger: function(){
            this.init();
        },
        display: function(){
            if(!this.display){
                this.$refs.contactUsForm.resetValidation();
                this.init();
            }
        }
    }
}
</script>

<style module>
.contactUsCard {
    position: relative;
    background-position: bottom right;
}

.closeBtnWrapper {
    position: absolute;
    top: 15px;
    right: 15px;
}

.contactUsTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5% 0 0;
}

.contactUsTitleWrapper h3 {
    font-size: 1.7rem;
}

.contactUsTitleWrapper p {
    font-size: 0.9rem;
    color: grey;
}

.contactUsLabel {
    margin-bottom: 0 !important;
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.supportLabel {
    font-size: 14px;
    color: grey;
}

.h3Heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color:var(--v-brandBlue-base);
}

.h3HeadingMobile {
    font-size: 20px !important;
    line-height: 24px !important;
}

</style>