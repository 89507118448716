const SpecialtyList = [
    {text: "list_specialty > Anesthesia", value: "anesthesia"}, 
    {text: "list_specialty > Cardiology", value: "cardiology"},
    {text: "list_specialty > Cardiothoracic Surgery", value: "cardiothoracic_surgery"},
    {text: "list_specialty > Clin Immun / Allergy", value: "clin_immun_allergy"},
    {text: "list_specialty > Community Medicine", value: "community_medicine"},
    {text: "list_specialty > Critical Care Medicine", value: "critical_care_medicine"},
    {text: "list_specialty > Dentistry", value: "dentisitry"},
    {text: "list_specialty > Dermatology", value:'dermatology'},
    {text: "list_specialty > Emergency Medicine", value: "emergency_medicine"},
    {text: "list_specialty > Endocrinology", value: "endocrinology"},
    {text: "list_specialty > Family Medicine", value: "family_medicine"},
    {text: "list_specialty > Gastroenterology", value: "gastroenterology"},
    {text: "list_specialty > General Pathology", value: "general_pathology"},
    {text: "list_specialty > General Surgery", value: "general_surgery"},
    {text: "list_specialty > General Surgical Oncology", value: "general_surgical_oncology"},
    {text: "list_specialty > Geriatric Medicine", value: "geriatric_medicine"},
    {text: "list_specialty > Gynecologic Oncology", value: "gynecologic_oncology"},
    {text: "list_specialty > Hematological Pathology", value: "hematological_pathology"},
    {text: "list_specialty > Hematology", value: "hematology"},
    {text: "list_specialty > Infectious Disease", value: "infectious_disease"},
    {text: "list_specialty > Internal Medicine", value: "internal_medicine"},
    {text: "list_specialty > Medical Scientist", value: "medical_scientist"},
    {text: "list_specialty > Medical Biochemistry", value: "medical_biochemistry"},
    {text: "list_specialty > Medical Genetics", value: "medical_genetics"},
    {text: "list_specialty > Medical Microbiology", value: "medical_microbiology"},
    {text: "list_specialty > Medical Oncology", value: "medical_oncology"},
    {text: "list_specialty > Nephrology", value: "nephrology"},
    {text: "list_specialty > Neurology", value: "neurology"},
    {text: "list_specialty > Neuropathology", value: "neuropathology"},
    {text: "list_specialty > Neurosurgery", value: "neurosurgery"},
    {text: "list_specialty > Nuclear Medicine", value: "nuclear_medicine"},
    {text: "list_specialty > Nursing", value: "nursing"},
    {text: "list_specialty > Obstetrics & Gynecology", value: "obstetrics_gynecology"},
    {text: "list_specialty > Ophthalmology", value: "ophthalmology"},
    {text: "list_specialty > Orthopedic Surgery", value: "orthopdic_surgery"},
    {text: "list_specialty > Otolaryngology", value: "otolaryngology"},
    {text: "list_specialty > Pediatric Cardiology", value: "pediatric_cardiology"},
    {text: "list_specialty > Pediatric Emergency Medicine", value: "pediatric_emergency_medicine"},
    {text: "list_specialty > Pediatric General Surgery", value: "pediatric_general_surgery"},
    {text: "list_specialty > Pediatric Hematology Oncology", value: "pediatric_hematology_oncology"},
    {text: "list_specialty > Pediatrics", value: "pediatrics"},
    {text: "list_specialty > Pharmacy", value: "pharmacy"},
    {text: "list_specialty > Physical Medicine & Rehabilitation", value: "physicial_medicine_rehabilitation"},
    {text: "list_specialty > Physician - General Practice", value: "physician_general_practice"},
    {text: "list_specialty > Plastic Surgery", value: "plastic_surgery"},
    {text: "list_specialty > Psychiatry", value: "psychiatry"},
    {text: "list_specialty > Public Health", value: "public_health"},
    {text: "list_specialty > Radiology", value: "radiology"},
    {text: "list_specialty > Radiation Oncology", value: "radiation_oncology"},
    {text: "list_specialty > Respirology", value: "respirology"},
    {text: "list_specialty > Rheumatology", value: "rheumatology"},
    {text: "list_specialty > Urology", value: "urology"},
    {text: "list_specialty > Other", value: "other"}
];

export default SpecialtyList