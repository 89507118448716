<template>
<div>
    <h3 align='center' style="font-weight: 600">
        <str :index="'editorial_board > title'"/>
    </h3>
    <v-container>
        <v-row class="text-center">
            <v-spacer/>
            <v-col cols="12" lg="3" md="3" sm="4">
                <Member :member="$Member_1" style="display: inline-block;"/>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="4">
                <Member :member="$Member_2" style="display: inline-block;"/>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="4">
                <Member :member="$Member_3" style="display: inline-block;"/>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-container>
</div>
</template>

<script>
import BoardMember_1 from './assets/img_1.png';
import BoardMember_2 from './assets/img_2.png';
import BoardMember_3 from './assets/img_3.png';
import Member from './member.vue'

export default {
    components: {
        Member
    },
    computed: {
        $Member_1: function(){
            return {
                name: {"en": "Doron Almagor", "fr": "Doron Almagor"},
                credentials: {"en":"MD, FRCPC", "fr":"M.D., FRCPC"},
                origin: {"en": "Toronto, Ontario", "fr":"Toronto (Ontario)"},
                image: BoardMember_1
            }
        },
        $Member_2: function(){
            return {
                name: {"en": "Joan Flood", "fr": "Joan Flood"},
                credentials: {"en":"MD", "fr":"M.D."},
                origin: {"en": "Scarborough, Ontario", "fr":"Scarborough (Ontario)"},
                image: BoardMember_2
            }
        },
        $Member_3: function(){
            return {
                name: {"en": "E. Lila Amirali", "fr": "Evangelia Amirali"},
                credentials: {"en":"MD, MMgmt(IMHL), FRCPC, FAPA", "fr":"M.D., MMgmt (IMHL), FRCPC, FAPA"},
                origin: {"en": "Montréal, Québec", "fr":"Montréal (Québec)"},
                image: BoardMember_3
            }
        }
    }
}
</script>

<style>

</style>