<template>
<div>
    <h3 class="ml-12">Data Dashboard</h3>
    <v-container>
        <v-row>
            <v-col cols="12" lg="3" md="3">
                <v-card>
                    <v-card-title class="headline justify-center"># of Unique Logins</v-card-title>
                    <v-card-text class="text-center">
                        <span class="brandOrangeAccent2--text display-1">{{$first_logins}}</span>/<span class="headline">{{$invitations_send}}</span><span>(invitations sent)</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" md="3">
                <v-card>
                    <v-card-title class="headline justify-center"># of PPT Downloads</v-card-title>
                    <v-card-text class="text-center">
                        <span class="brandOrangeAccent2--text display-1">{{$ppt_counter.requests_total}}</span>
                        <!-- Total
                        /
                        <span class="brandOrangeAccent2--text display-1">{{$ppt_downloads_unique}}</span> Unique
                        -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" md="3">
                <v-card>
                    <v-card-title class="headline justify-center"># of Videos Watched</v-card-title>
                    <v-card-text class="text-center">
                        <span class="brandOrangeAccent2--text display-1">{{$video_counter.requests_total}}</span> 
                        <!-- Total
                        /
                        <span class="brandOrangeAccent2--text display-1">{{$video_views_unique}}</span> Unique
                        -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" md="3">
                <v-card>
                    <v-card-title class="headline justify-center"># of Acknowledgements</v-card-title>
                    <v-card-text class="text-center">
                        <v-row align="center" justify="center">
                            <div class="brandOrangeAccent2--text display-1 mx-8">{{$download_acknowledgement_counter}}</div>
                            <v-btn small dark color="var(--v-brandOrange-base)" @click="startExportCVS();"><str :index="'button > export csv'"/></v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>            
        </v-row>
    </v-container>

    <v-data-table :headers="headers" :items="user_records"
        :loading-text="'...loading...'"
        :hide-default-footer="true"
        :disable-pagination="true"
        :footer-props="{
            itemsPerPageOptions: [50,100,200,-1]
        }"
        :multi-sort="true"
        class="elevation-1 tracker_table"
        dense     
    />
</div>
</template>

<script>
import SpecialtyList from '@/components/Forms/SpecialtyList.js';
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    props: {
        programs: {
            type: Array,
            required: true
        }
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){
            this.fetch = new function(){

                this.users = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_users';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let users = response.data[action][call].results;                             
                                for(let i=0; i<users.length; i++){
                                    users[i] = component.parseJSON(users[i]);
                                    if (users[i].type=='user'){
                                        component.user_type_map.user.push(users[i].id);
                                    }
                                    else if (users[i].type=='client'){
                                        component.user_type_map.client.push(users[i].id);
                                    }
                                    else if (users[i].type=='staff'){
                                        component.user_type_map.staff.push(users[i].id);
                                    }
                                    else if (users[i].type=='admin'){
                                        component.user_type_map.admin.push(users[i].id);
                                    }
                                    else if (users[i].type=='rep'){
                                        component.user_type_map.rep.push(users[i].id);
                                    }
                                    else if (users[i].type=='tester'){
                                        component.user_type_map.tester.push(users[i].id);
                                    }
                                }
                                component.users = users;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.email_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_activation_email_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.activation_emails = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.download_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_download_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.downloads = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.view_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_view_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.views = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.login_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_login_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call];
                                
                                component.logs.logins = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }
        }
        
        component.dataHandler = dataHandler

        let machineConfig = {
            id: 'admin',
            context: {
                error: {
                dataHandler: null
                }
            },
            initial: 'idle',
            states: {
                idle: {
                    always: '#fetch.users'
                },
                ready: {
                    on: {
                        'reload' : '#admin.idle'
                    }
                },
                fetch: {
                    id: "fetch",
                    initial: 'idle',
                    states: {
                        idle: {},
                        users: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.fetch.users(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#fetch.logs',
                                onError: '#admin.error'
                            }
                        },
                        logs: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    let promises = [];
                                    let email_logs = dataHandler.fetch.email_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let login_logs = dataHandler.fetch.login_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let download_logs = dataHandler.fetch.download_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let view_logs =  dataHandler.fetch.view_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })

                                    promises.push(email_logs);
                                    promises.push(login_logs);
                                    promises.push(download_logs);
                                    promises.push(view_logs);

                                    Promise.all(promises).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#admin.ready',
                                onError: '#admin.error'
                            }
                        }
                    }
                },
                error: {}
            }

        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();

    },
    data: function(){
        return {
            state: null,
            context: null,
            service: null,
            users: null,
            logs: {
                activation_emails: null,
                downloads: null,
                views: null,
                logins: null
            },
            filter: 'user',
            user_type_map: {
                user: [],
                client: [],
                staff: [],
                admin: [],
                rep: [],
                tester: []
            }
        }
    },
    computed: {    
        $invitations_send: function(){
            let self = this;            
            return this.logs.activation_emails ? this.logs.activation_emails.filter( elem => self.user_type_map[self.filter].includes(elem.parameters.target)).map(elem => {return elem.parameters}).length : null;
        },
        $first_logins: function(){
            let self = this;
            let firstLoginUserIds = this.logs.logins ? 
                this.logs.logins.first_logins.filter( elem => self.user_type_map[self.filter].includes(elem.user_id)).map( elem => {return elem.user_id} ) 
                : [];
            let validatedUsers = this.users ? 
                this.users.filter( elem => elem.validation_timestamp && self.user_type_map[self.filter].includes(elem.id)).map(elem => {return elem.id}) 
                : [];
            let distinctLoggedUserId = [...new Set([...firstLoginUserIds,...validatedUsers])];
            return distinctLoggedUserId.length;
        },
        maps: function(){
            let maps = {
                last_logins: {},
                first_logins: {},
                logins: {}
            }

            let first_logins = this.logs.logins ? this.logs.logins.first_logins : [];
            for(let i=0; i<first_logins.length; i++){
                let record = first_logins[i];
                maps.first_logins[record.user_id] = record;
                maps.logins[record.user_id] = record.logins;
            }

            let last_logins = this.logs.logins ? this.logs.logins.last_logins : [];
            for(let i=0; i<last_logins.length; i++){
                let record = last_logins[i];
                maps.last_logins[record.user_id] = record;
            }

            return maps;
        },
        headers: function(){
            let headers = [
                {text:'Name', value:'name', order: 1},
                {text:'Type', value:'type' , order: 2},
                {text:'Validation Date', value:'validation_date', order: 5},
                {text:'Last Login', value:'last_login', order: 6},
                {text:'Login Count', value:'login_count', order: 7},
                {text:'Acknowledgement Date', value:'download_acknowledgement', order: 8}
            ];

            return headers;
        },
        dataExportHeaders: function(){
            let programHeaders = [];
            for(let pi=0; pi<this.programs.length; pi++){
                programHeaders.push( {text:this.programs[pi].name.en, value:'pid_'+this.programs[pi].id, order: 100+this.programs[pi].id });
            }
            let customHeaders = [
                {text:'Preferred Language', value:'preferred_language', order: 4},
                {text:'Specialty', value:'specialty', order: 3}
            ];
            
            return [...this.headers, ...customHeaders, ...programHeaders].sort((firstEl, secondEl) => {
                if (firstEl.order < secondEl.order) {return -1 }
                else if (firstEl.order > secondEl.order) {return 1}
                else { return 0}
            });
        },
        user_records: function(){
            let self = this;
            let user_records = [];
            let users = this.users;

            if(users){
                for(let i=0; i<users.length; i++){
                    let user = users[i];
                    let user_record = {
                        name: user.firstname + ' ' + user.lastname,
                        type: user.type,
                        validation_date: user.validation_timestamp,
                        last_login: this.maps.last_logins[user.id] ? this.maps.last_logins[user.id].timestamp : null,
                        login_count: this.maps.logins[user.id],
                        download_acknowledgement: user.download_acknowledgement!=='0000-00-00 00:00:00' ? user.us_east_downloadacknowledgement : null,
                        specialty: SpecialtyList.filter(specialty => specialty.value==user.specialty).reduce((reduced, specialty) => reduced + self.labelTerm(specialty.text), ""),
                        preferred_language: user.language 
                    };
                    for(let pi=0; pi<this.programs.length; pi++){
                        user_record['pid_'+this.programs[pi].id] = user.programs && user.programs.includes(this.programs[pi].id) ? '\u2713' : null;
                    }
                    user_records.push(user_record);
                }
            }

            return user_records;
        },
        $ppt_counter: function(){
            let programs = this.programs;
            let counter = {}
            let count_filter = {}

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                counter[program.id] = {};
                counter.requests_total = 0;
                count_filter[program.id] = {};

                for(let t=0; t<program.topics.length; t++){
                    let topic = program.topics[t];
                    counter[program.id][t] = {}
                    count_filter[program.id][t] = {}

                    for(let language in topic.files.ppt){
                        counter[program.id][t][language] = 0
                        count_filter[program.id][t][language] = []
                    }
                }
            }

            let downloads = this.logs.downloads;
            if(downloads){
                for(let i=0; i<downloads.length; i++){
                    let record = downloads[i];
                    let params = record.parameters;
                    if (this.user_type_map[this.filter].includes(record.user_id)){
                        counter.requests_total++;
                    }
                    
                    //following covers downloads by distinct key of user_pptfile(lang)
                    if (this.user_type_map[this.filter].includes(record.user_id) && !this.in_array(record.user_id,count_filter[params.program_id][params.topic_index][params.language])){
                        counter[params.program_id][params.topic_index][params.language]++;
                        count_filter[params.program_id][params.topic_index][params.language].push(record.user_id);
                    }
                }
            }

            return counter;
        },
        $ppt_downloads_unique: function(){
            let ppt_counter = this.$ppt_counter;
            let total=0;
            for(let program_id in ppt_counter){
                let program = ppt_counter[program_id];
                for(let t in program){
                    let downloads = program[t];
                    for(let language in downloads){
                        let count = downloads[language];
                        total+=count;
                    }
                }
            }

            return total;
        },
        $video_counter: function(){
            let programs = this.programs;
            let counter = {}
            let count_filter = {}

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                counter[program.id] = {};
                counter.requests_total = 0;
                count_filter[program.id] = {};

                for(let t=0; t<program.topics.length; t++){
                    let topic = program.topics[t];
                    counter[program.id][t] = {}
                    count_filter[program.id][t] = {}

                    for(let language in topic.files.video){
                        counter[program.id][t][language] = 0
                        count_filter[program.id][t][language] = []
                    }
                }
            }

            let views = this.logs.views;
            if(views){
                for(let i=0; i<views.length; i++){
                    let record = views[i];
                    let params = record.parameters;
                    if (this.user_type_map[this.filter].includes(record.user_id)){
                        counter.requests_total++;
                    }
                    
                    //following covers downloads by distinct key of user_mp4file(lang)
                    if (this.user_type_map[this.filter].includes(record.user_id) && !this.in_array(record.user_id,count_filter[params.program_id][params.topic_index][params.language])){
                        counter[params.program_id][params.topic_index][params.language]++;
                        count_filter[params.program_id][params.topic_index][params.language].push(record.user_id);
                    }
                }
            }

            return counter;
        },
        $video_views_unique: function(){
            let video_counter = this.$video_counter;
            let total=0;
            for(let program_id in video_counter){
                let program = video_counter[program_id];
                for(let t in program){
                    let views = program[t];
                    for(let language in views){
                        let count = views[language];
                        total+=count;
                    }
                }
            }

            return total;
        },
        $download_acknowledgement_counter: function(){
            return this.user_records.reduce((aggregatedValue, elem) => {return elem.download_acknowledgement!==null ? aggregatedValue + 1 : aggregatedValue}, 0);
        }
    },
    methods: {
        startExportCVS: function () {
            let exportData = [];
            let headers = [...this.dataExportHeaders];
            for (let recordKey in this.user_records) {
                let tempObj = {};
                for (let headerKey in headers) {
                    tempObj[headers[headerKey]['text']] = 
                            this.user_records[recordKey][headers[headerKey]['value']]!==undefined && this.user_records[recordKey][headers[headerKey]['value']]!==null ?
                                this.user_records[recordKey][headers[headerKey]['value']] : '';
                }
                exportData.push(tempObj);
            }

            this.keepExporting(exportData);
        },

        keepExporting: function (exportData) {
            let data, filename, link;
            let csv = this.convertToCSV({
                data: exportData
            });
            let BOM = "\uFEFF";
            if (csv === null) {
                alert("No data available");
                return;
            }
            let now = new Date();
            let date = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
            filename = `ADHD_HUB-Dump-${date}.csv`;
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${BOM}` + csv;
            }
            data = encodeURI(csv);

            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            link.click();
        },

        /* eslint-disable no-useless-escape */
        convertToCSV: function (args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args.data || null;
            if (data == null || !data.length) {
                return null;
            }

            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            result = "";
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            data.forEach(function (item) {
                ctr = 0;
                keys.forEach(function (key) {
                    if (ctr > 0) {
                        result += columnDelimiter;
                    }
                    result += '\"' + item[key] + '\"';
                    ctr++;
                });
                result += lineDelimiter;
            });

            return result;
        }
    }
}
</script>

<style lang="css" scoped>
/deep/ .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    color: var(--v-brandBlack-base) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    background: var(--v-backgroundGrayAccent1-darken1) !important;
}

.v-btn {
    text-transform: initial;
    font-style: normal;
    font-size: 15px !important;
}
</style>

<style>

</style>