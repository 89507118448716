<template>
    <v-app v-resize="onResize">
    
        <v-app-bar
            v-if="$store.getters.user && ready"
            app
            color="white"
            dark
            flat
            :class="[{['justify-center']:$vuetify.breakpoint.smAndDown}, [$vuetify.breakpoint.mdAndUp ? 'pr-15' : 'pr-2']]"
        >
            
            <v-app-bar-nav-icon light @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
            
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              :src="images.adhd_hub"
              transition="scale-transition"
              width="200"
              :class="['app-logo', {['app-logo-mobile']:$vuetify.breakpoint.smAndDown}, {['app-logo-xs-mobile']:$vuetify.breakpoint.xsOnly}]"
              role="button"
              @click="$router.push('/')"
            />

            <v-spacer></v-spacer>

            <div id="appbar-utils-wrapper" class="d-flex align-center">
                <ContactIcon class="hidden-sm-and-down" style="margin:15px;" :navTrigger="navContactUsTrigger" @triggered="navContactUsTrigger=false;"/>

                <UserIcon
                    :navTrigger="navEditProfileTrigger" @triggered="navEditProfileTrigger=false;"
                    :user="$store.getters.user" @logout="service.send('jwt.logout')" 
                    class="hidden-sm-and-down" style="margin-left: 2px; margin-right: 27px;"
                    />

                <LanguageSelect :class="['language-select', {['language-select-mobile']:$vuetify.breakpoint.smAndDown}]"/>

            </div>            
        </v-app-bar>

        <v-navigation-drawer            
            v-model="drawer"
            temporary
            app
            dark
            >
            <v-list dense>
                <v-list-item link @click="drawer=false; $router.push('/')">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <str :index="'button > home'" />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="drawer=false; navEditProfileTrigger=!navEditProfileTrigger">
                    <v-list-item-action>
                        <v-icon>mdi-account-edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <str :index="'menu > edit_profile'" />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                

                <v-list-item link @click="drawer=false;navContactUsTrigger=!navContactUsTrigger">
                    <v-list-item-action>
                        <v-icon>mdi-headset</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <str :index="'button > contact_us'" />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <v-list-item link @click="drawer=false;navSiteRegulationTrigger=!navSiteRegulationTrigger">
                    <v-list-item-action>
                        <v-icon>mdi-file-move-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <str :index="'site_regulation > terms of use'"/>/<str :index="'site_regulation > privacy policy'"/>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <v-list-item link @click="drawer=false;service.send('jwt.logout')">
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <str :index="'user_icon > logout'"/>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>



        <v-main dark>
            <fieldset v-if="debug">
                <legend>STATES</legend>
                <ul>
                  <li>READY: {{ready}}</li>
                  <li>CSRF: {{state.csrf}}</li>
                  <li>JWT: {{state.jwt}}</li>
                  <li>Library: {{state.fetch.library}}</li>
                  <li>LOGIN: {{state.ui.login}}</li>
                  <li>AUTHORIZED: {{authorized}}</li>
                </ul>
            </fieldset>

            <Login :debug="debug" :show="state.ui.login=='show'" @close="service.send('ui.login.hide')"/>
            <router-view v-if="authorized && ready" :debug="debug" :language="language" :programs="programs" :my_programs="my_programs"/>
        </v-main>


        <v-footer v-if="user"
          app
          light
          height="50"
          color="var(--v-brandWhite-base)"
          class="hidden-sm-and-down justify-center"
          absolute
        >
            <Footer :language="language" :navSiteRegulationTrigger="navSiteRegulationTrigger" @triggered="navSiteRegulationTrigger=false;"/>

            <img style="height: 40px; position:absolute;right:0" :src="images.neuroscience"/>
        </v-footer>
  </v-app> 
</template>

<script>
import Vue from 'vue'
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import Login from '@/components/Login/Login.vue'
import ContactIcon from '@/components/UI/ContactIcon.vue'
import UserIcon from '@/components/UI/UserIcon.vue'
import LanguageSelect from '@/components/UI/LanguageSelect.vue'
import Footer from '@/components/Footer/Footer.vue'

import adhd_hub_en from '@/assets/logos/adhd_hub_en.png'
import adhd_hub_fr from '@/assets/logos/adhd_hub_fr.png'
import neuroscience from '@/assets/logos/neuroscience.png'

export default {
  name: 'App',
  components: {
    Login,
    ContactIcon,
    UserIcon,
    LanguageSelect,
    Footer
  },
  created: function(){
    let component = this;

    const csrf = new function(){
        
        this.request = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){
                  component.sendRequest({
                    action: 'csrf',
                    call: 'request'
                  }).then(function(response){
                      var token = response.data.csrf.request.token
                      component.$store.dispatch('csrf',token);
                      resolve();
                  },function(response){
                    reject(response)
                  })
                }else{
                  setTimeout(function(){
                      context.csrf.value = 'simulated-csrf';
                      resolve();
                  },500)
                }
            })
        }

        this.validate = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){                  
                  component.sendRequest({
                    action: 'csrf',
                    call: 'validate',                    
                  }).then(function(response){
                      if(response.data['csrf-check'].result===true){
                        resolve();
                      }else{
                        component.$store.dispatch('clear_csrf');
                        reject();
                      }
                  },function(){
                    reject()
                  })                  
                }else{
                  setTimeout(function(){
                      context.csrf.value = 'simulated-csrf-validated';
                      resolve();
                  },500)
                }
            })
        }
    }

    const jwt = new function(){
        
        this.validate = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){                  
                    if(component.$store.getters.jwt){
                        component.sendRequest({
                          action: 'login',
                          call: 'validate',                    
                        }).then(function(response){
                            if(response.data.login.validate.pass===true){
                              resolve();
                            }else{
                                component.$store.dispatch('clear_jwt').then(function(){
                                reject();
                              })
                            }
                        },function(){
                          reject()
                        })                  
                    }else{
                        reject()
                    }
                }else{
                  setTimeout(function(){
                      if(context.authenticate.result){
                          context.jwt.value = 'simulated-jwt-validated';
                          resolve();
                      }else{
                        context.jwt.value = null;
                          reject()
                      }
                  },500)
                }
            })
        }

        this.logout = function(component, context){
            return new Promise((resolve, reject)=>{
                let action = 'login';
                let call = 'logout';

                if(component.sendRequest){                  
                    component.sendRequest({
                        action: action,
                        call: call
                    }).then(function(){                        
                        resolve();
                    },function(response){
                        context.error = response
                        reject();
                    })
                }else{
                    setTimeout(function(){
                        if(context.authenticate.result){
                            resolve();
                        }else{
                            reject()
                        }
                    },500)
                }
            })
        }
        
        this.refresh = function(){
            return new Promise((resolve, reject)=>{
                let action = 'forms';
                let call = 'refresh';
                if(component.sendRequest){                  
                    component.sendRequest({
                        action: action,
                        call: call,
                        user_id: component.$store.getters.user.id
                    }).then(function(response){
                        if (response.data[action][call].token){
                            let token = response.data[action][call].token;
                            component.$store.dispatch('jwt',token);
                            resolve(token);
                        }else{
                            reject();
                        }
                    },function(){
                        reject();
                    });
                }else{
                    resolve();
                }
            });            
        }        

    }

    const dataHandler = new function(){
        this.fetch = new function(){

            this.programs = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'program';
                    let call = 'fetch_programs';

                    if(component.sendRequest){
                        component.sendRequest({
                            action: action,
                            call: call
                        }).then(function(response){
                            let programs = response.data[action][call].results;
                            for(let i=0; i<programs.length; i++){
                              programs[i] = component.parseJSON(programs[i]);
                            }
                            
                            component.$store.dispatch('programs',programs);
                            context.error.dataHandler = null;
                            resolve();
                        },function(response){
                          context.error.dataHandler = response;
                          reject();
                        })
                    }else{
                        setTimeout(function(){
                            resolve()
                        },1000)
                    }
                })
            }
            
            this.library = function(component, context){
                return new Promise((resolve, reject)=>{
                    if(component.sendRequest){
                        component.sendRequest({
                            action: 'str',
                            call: 'library'
                        }).then(function(response){
                            context.error.dataHandler = null;
                            component.$store.dispatch('library',response.data.str.library);
                            resolve();
                        },function(response){
                          context.error.dataHandler = response;
                          reject();
                        })
                    }else{
                        setTimeout(function(){
                            resolve()
                        },1000)
                    }
                })
            }
        }
    }    

    let machineConfig = {
        id: 'app',
        context: {
          error: {
            app: null,
            csrf: null,
            jwt: null,
            dataHandler: null
          }
        },
        type: 'parallel',
        states: {
            app: {
              initial: 'idle',
              states: {
                idle: {},
                ready: {},
                fetch: {}
              }
            },
            ui: {
              id: 'ui',
              type: 'parallel',
              states: {
                login: {
                  id: 'login',
                  initial: 'hide',
                  states: {
                    show: {},
                    hide: {}
                  },
                  on: {
                    'ui.login.show':'#ui.login.show',
                    'ui.login.hide':'#ui.login.hide'
                  }
                }
              }
            },
            csrf: {
              id: 'csrf',
              initial: 'init',
              states: {
                init: {
                  invoke: {
                    src: () => new Promise((resolve, reject)=>{
                      if(component.cookie && component.cookie.get('csrf')){
                        var token = component.cookie.get('csrf')
                        component.$store.dispatch('csrf', token);
                        resolve();
                      }else{
                        reject();
                      }
                    }),
                    onDone: {
                      target: '#csrf.validate'
                    },
                    onError: {
                      target: '#csrf.request'
                    }
                  }                
                },
                request: {
                  invoke: {
                    src: (context) => new Promise((resolve, reject)=>{
                      csrf.request(component, context).then(function(){
                        resolve()
                      },function(){
                        reject()
                      })
                    }),
                    onDone: {
                      target: '#csrf.validate'
                    },
                    onError: {
                      target: '#csrf.init'
                    }                    
                  }
                },
                validate: {
                  invoke: {
                    src: (context) => new Promise((resolve, reject)=>{
                      csrf.validate(component, context).then(function(){
                        resolve()
                      },function(){
                        reject()
                      })
                    }),
                    onDone: {
                      target: ['#csrf.valid','#fetch.library.active','#fetch.programs.active']
                    },
                    onError: {
                      target: '#csrf.request'
                    }
                  }                     
                },
                valid: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      resolve();
                    }),
                    onDone: {
                      target: '#jwt.init'
                    }
                  }
                },
                error: {}
              },
              on: {
                'csrf.request' : '#csrf.request'
              }
            },
            session: {
              id: 'session',
              initial: 'idle',
              states: {
                idle: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.stop_session().then(function(){
                        resolve();
                      })
                    })
                  },
                  on: {
                    'session.start' : '#session.start'
                  }
                },
                start: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.start_session().then(function(){
                        resolve();
                      })
                    }),
                    onDone: {
                      target: '#session.active'
                    }
                  }
                },
                restart: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.restart_session().then(function(){
                        resolve();
                      })
                    }),
                    onDone: {
                      target: '#session.active'
                    }
                  }
                },
                active: {
                  on: {
                    'session.restart' : '#session.restart',
                    'session.stop' : '#session.idle',
                  }                  
                }
              }
            },
            jwt: {
              id: 'jwt',
              initial: 'idle',
              states: {
                idle: {},
                init: {
                    invoke: {
                      src: () => new Promise((resolve, reject)=>{
                        if(component.cookie && component.cookie.get('jwt')){
                          var token = component.cookie.get('jwt')
                          component.$store.dispatch('jwt',token);
                          resolve();
                        }else{
                          reject();
                        }
                      }),
                      onDone: {
                        target: '#jwt.validate'
                      },
                      onError: {
                        target: ['#jwt.idle']
                      }
                    }
                },
                validate: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            jwt.validate(component,context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: ['#jwt.valid','#ui.login.hide']
                        },
                        onError: {
                            target: ['#jwt.idle','#ui.login.show']
                        }
                    }
                },
                valid: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      if(component.$store.getters.entryURL){
                        component.$router.push(component.$store.getters.entryURL.path);
                        component.$store.dispatch('entryURL',null);
                      }
                      resolve();
                    }),
                    onDone: {
                      target: '#session.start'
                    }
                  }
                },
                logout: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{     
                            component.$store.dispatch('clear_jwt');
                            jwt.logout(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: ['#jwt.idle','#session.idle']
                        }
                    }
                },
                refresh: {
                    invoke: {
                        src: () => new Promise((resolve, reject)=>{
                            jwt.refresh().then(function(){
                                resolve();
                            }, function(){
                                reject();
                            });
                        }),
                        onDone: {
                            target: ['#session.active']
                        }                        
                    }
                }
              },
              on: {
                'jwt.init': '#jwt.init',
                'jwt.logout': '#jwt.logout',
                'jwt.refresh': '#jwt.refresh'
              }
            },
            fetch: {
              id: 'fetch',
              type: 'parallel',
              states: {
                programs: {
                  initial: 'idle',
                  states: {
                    idle: {},
                    error: {},
                    active: {
                      invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                          dataHandler.fetch.programs(component, context).then(function(){
                            resolve();
                          },function(){
                            reject();
                          })
                        }),
                        onDone: {
                          target: '#fetch.programs.idle'
                        },
                        onError: {
                          target: '#fetch.programs.error'
                        }
                      }
                    }
                  }

                },
                library: {
                  initial: 'idle',
                  states: {
                    idle: {},
                    ready: {},
                    error: {},
                    active: {
                      invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                          dataHandler.fetch.library(component, context).then(function(){
                            resolve();
                          },function(){
                            reject();
                          })
                        }),
                        onDone: {
                          target: '#fetch.library.ready'
                        },
                        onError: {
                          target: '#fetch.library.error'
                        }
                      }
                    }
                  }

                }
              },
              on: {
                'fetch.programs':'#fetch.programs.active',
                'fetch.library':'#fetch.library.active'
              }
            }
        }

    }

    const machine = Machine(machineConfig,{
        guards: {
            allow_comms: function(context){
                return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
            },
            allow_login: function(context){
                return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
            },
            allow_logout: function(context){
                return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
            }
        }
    });


    this.service = interpret(machine)
    this.state = machine.initialState
    this.context = machine.context

    var self = this
    self.service.onTransition(state => {
        self.state = state.value;
        self.context = state.context
    }).start();

    Vue.prototype.$controller = this;
  },

  data: () => ({
    drawer: false,
    navContactUsTrigger: false,
    navEditProfileTrigger: false,
    navSiteRegulationTrigger: false,
    debug: false,
    popups: {
      login: false
    },
    service: null,
    state: null,
    context: null,
    session: null,
    config: {
      session_timeout: 2 * 60 * 60 * 1000
    },
    login_trigger_timeout: null
  }),
          
  mounted: function() {
      this.onResize();
  },          

  methods: {
    onResize: function() {
        this.$store.dispatch('windowSize', {
            x: window.innerWidth,
            y: window.innerHeight
        });
    },      
    start_session: function(){
      return new Promise((resolve)=>{
        let self = this;
        clearTimeout(self.session);
        self.session = setTimeout(function(){
          self.service.send('jwt.logout')
        },self.config.session_timeout);
        resolve();
      })
    },
    restart_session: function(){
      return this.start_session();
    },
    stop_session: function(){
      return new Promise((resolve)=>{
        clearTimeout(this.session);
        resolve();
      })
    },
    show_login: function(){
      let self = this;
      clearTimeout(self.login_trigger_timeout);
      if(!self.ready){
        self.login_trigger_timeout = setTimeout(function(){
          self.show_login();
        },800)
      }else{
          if(self.$route.name=='Login'){
            switch(this.state.jwt){
              case"valid":
                self.$router.push('/');          
              break;

              case"idle":
                self.service.send('ui.login.show');
              break;
            }
          }
      }
    }
  },

  computed: {
    ready: function(){
      return this.state && (this.state.csrf=='valid' && this.state.fetch.library=='ready' && (this.state.jwt=='idle' || this.state.jwt=='valid'));
    },
    jwt: function(){
      return this.$store.getters.jwt
    },
    language: function(){
      return this.$store.getters.language
    },
    images: function(){
      return {
        adhd_hub: this.language=='fr' ? adhd_hub_fr : adhd_hub_en,
        neuroscience: neuroscience
      }
    },
    programs: function(){
      return this.$store.getters.programs;
    },
    user: function(){
      return this.$store.getters.user;
    },
    my_programs: function(){
      let my_programs = [];
      let user = this.user;
      let programs = this.programs;
      if(user && user.programs && programs){
        for(let i=0; i<programs.length; i++){
          let program = programs[i];
          if(this.in_array(program.id, user.programs) || this.in_array(this.user.type,['staff','admin'])){
            my_programs.push(program);
          }
        }
      }

      return my_programs;
    },
    library: function(){
      return this.$store.getters.library;
    },
    authorized: function(){
      return (this.state && this.$route.meta && this.$route.meta.requiresAuth) ? this.state.jwt=='valid' : true;
    },
    path: function(){
      return this.$route.name;
    }
  },

  watch: {
    jwt: function(){
      if(this.jwt){
        this.service.send('jwt.init');
        }else{          
          this.$router.push('/login')
      }
    },
    ready: function(){
      this.show_login();
      // let self = this;
      // if(self.ready && self.$route.name=='Login'){
      //   switch(this.state.jwt){
      //     case"valid":
      //       self.$router.push('/');          
      //     break;

      //     case"idle":
      //       self.service.send('ui.login.show');
      //     break;
      //   }
      // }
    },
    authorized: function(){
      console.log('watch > authorized', this.authorized)
    },
    path: function(){
      if(this.path=='Login'){
        this.show_login();
      }
    }
  }
};
</script>

<style>
.v-snack .theme--dark.v-btn {
    color: var(--v-brandBlue-lighten4);
}
    
h3 {
    color: var(--v-brandOrangeAccent2-base);
    font-size: 1.5em;
}

#appbar-utils-wrapper .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 32px !important;
    height: 32px !important;
    display: flex!important;
    align-items: center!important;
    padding-right: 2px;
    padding-left: 9px;
}

#appbar-utils-wrapper .v-text-field--enclosed .v-input__append-inner {
    margin-top: 12px;
}

.language-select {
    min-width:93px;
    max-width:120px;
    width:93px;
    font-size:15px;
    line-height:18px;
    margin:15px
}

.language-select-mobile {
    min-width:65px;
    width:65px;
}

header .v-toolbar__content {
    justify-content: center;
}

header .app-logo {
    margin-left: 50px;
    cursor: pointer;
}
header .app-logo-mobile {
    margin-left: 0;
    position: absolute;
}

header .app-logo-xs-mobile {
    width: 160px !important;
}

nav .v-list-item .v-list-item__title {
    font-size: 0.94rem !important;
}
</style>