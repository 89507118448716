<template>
<div>
    <v-btn @click="target='terms-of-use'; show=true;"  style="font-size: 18px; font-weight: 500; line-height: 22px; text-transform:none;"
        class="mt-0 pt-0 brandBlack--text" :class="$style.footerLink" text link>
        <str :index="'site_regulation > terms of use'"/>
    </v-btn>
    |
    <v-btn @click="target='privacy-policy';show=true;" style="font-size: 18px; font-weight: 500; line-height: 22px; text-transform:none;"
        class="mt-0 pt-0 brandBlack--text" :class="$style.footerLink" text link>
        <str :index="'site_regulation > privacy policy'"/>
    </v-btn>    
    
    <v-dialog 
        v-model="show" 
        max-width="900" 
        :fullscreen="$vuetify.breakpoint.smAndDown || windowSize().y <= 550"
        no-click-animation
        hide-overlay
        :scrollable="false"
        >
            <SiteRegulation :language="language" :target="target" @close="show=false;target=null"/>
    </v-dialog>
</div>
</template>

<script>
import SiteRegulation from '@/components/SiteRegulation/SiteRegulation.vue'

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        navSiteRegulationTrigger: {
            type: Boolean
        }
    },
    components: {
        SiteRegulation
    },
    data: function(){
        return {
            show: false,
            target: null
        }
    },
    watch: {
        navSiteRegulationTrigger: function(after, before){
            if (after===true && after!==before){
                this.show = after;
                this.$emit("triggered")
            }
        }    
    }
}
</script>

<style module>
.footerLink {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
}
</style>

<style>
.privacyPolicyDialog {
    overflow-y: hidden !important;
}
</style>