<template>
<div>
    <v-dialog 
        v-model="display" 
        max-width="690" 
        persistent
        no-click-animation
        :fullscreen="windowSize().x <= 960 || windowSize().y <= 630"
        width='690'
        hide-overlay
        >
        <v-container mt-0 background="var(--v-background-base)">
            <v-row justify="center">
                <v-img
                  alt="logo"
                  class="shrink"
                  contain
                  :src="logoImage"
                  transition="scale-transition"
                  width="270"
                  style="cursor: pointer;"
                />
            </v-row>
        </v-container>
        
        <v-card v-if="state=='verify' || state=='search' || state.activate" class="rounded-lg" style="border: 1px solid var(--v-background-darken1);">
            <v-card-title>
                <v-spacer/>
                <ContactIcon :navTrigger="triggerContactUs" @triggered="triggerContactUs=false;"/>
                <LanguageSelect :key="'activate_language'" style="min-width:90px;max-width:120px;width:90px;font-size:12px;line-height:14px"/>
                <v-spacer/>
            </v-card-title>

            <v-card-text class="text-center">
                <div :class="$style.header" class="mb-8">
                    <str :index="'login > title'"/>
                </div>
                <div v-if="state=='verify' || allow" :class="['mb-4']" style="font-size: 18px;">
                    <str :index="'activate > title verify'"/>
                </div>
                <template v-if="state=='verify'">
                    <v-form ref="verifyForm" v-model="validVerifyForm" v-on:submit.prevent='verify'>
                        <v-row>
                            <v-spacer/>
                            <v-col cols="12" sm="4" class="py-0">
                                <v-text-field type="email" required :rules="formRules().validEmail" validate-on-change dense outlined v-model="form.email" @change="form.email = form.email.toLowerCase()" :label="labelTerm('form > email')">
                                    <template v-slot:message="{ message }">
                                        <str :index="message" />
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-spacer/>
                        </v-row>
                        <v-btn color="var(--v-brandOrange-base)" :dark="validVerifyForm===true" :disabled="!validVerifyForm" @click="verify"><str :index="'button > confirm'"/></v-btn>
                    </v-form>
                </template>

                <template v-if="(state.activate || state.activate_account)">
                    <v-container :class="['pa-0', {['innerWrapper']:$vuetify.breakpoint.mdAndUp}]">
                        <UserForm v-if="allow" :user="account" @update="update_form" @valid="setActivateFormValid" :options="{show:['firstname','lastname','password','language'],password:{force_change:true, autoaccept_on_match: true}}"/>

                        <div v-if="allow===false" class="text-center brandBlack--text mb-4" style="font-size: 18px;">
                            <str :index="'message > encountered_error'"/>
                            <v-btn text link class="brandBlueAccent1--text" style="text-decoration: underline; font-size: 18px;padding-left: 4px;" @click="triggerContactUs=true;">
                                <str index="contact_us > title"/>!
                            </v-btn>
                        </div>
                    </v-container>
                    <v-btn :disabled="!allow_activate" @click="activate_account" :dark="allow_activate" color="var(--v-brandOrange-base)" style="font-size:18px;"><str :index="'button > activate_account'"/></v-btn>
                </template>
            </v-card-text>
        </v-card>

        <v-row v-else-if="state.activate_account=='done'" class="rounded-lg" align="center" justify="center" style="height:200px; background-color: var(--v-brandWhite-base); border: 1px solid var(--v-background-darken1);">
            <v-card  class="rounded-lg d-flex flex-column align-center justify-center" max-width="90%" style="border: 1px solid var(--v-background-darken1); background: var(--v-background-base);">
                <v-card-title class="justify-center">
                    <str :index="'message > activate_account > success'"/>
                </v-card-title>
            </v-card>
        </v-row>
    </v-dialog>

    <v-snackbar v-model="snackbar.display">
        <span v-if="snackbar.message">
            <str :index="snackbar.message"/>
        </span>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import UserForm from '@/components/Forms/UserForm.vue'
import ContactIcon from '@/components/UI/ContactIcon.vue'
import LanguageSelect from '@/components/UI/LanguageSelect.vue'
import LogoEn from '@/assets/logos/adhd_hub_en.png'
import LogoFr from '@/assets/logos/adhd_hub_fr.png'

export default {
    components: {
        UserForm,
        ContactIcon,
        LanguageSelect
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){
            this.fetch = new function(){

                this.account = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'activate_account';
                        let call = 'fetch_user';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                data: component.params,
                                method: 'id'
                            }).then(function(response){
                                let output = response.data[action][call];
                                context.error.dataHandler = null;
                                if(output.results){
                                    resolve(output.user);
                                }else{
                                    context.error.dataHandler = output.result;
                                    reject();
                                }
                            },function(response){
                            context.error.dataHandler = response;
                            reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.user = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'activate_account';
                        let call = 'fetch_user';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                data: component.method=='id' ? component.params : component.form,
                                method: component.method
                            }).then(function(response){
                                let output = response.data[action][call];
                                context.error.dataHandler = null;
                                if(output.results){
                                    component.account = component.parseJSON(output.user);
                                    resolve();
                                }else{
                                    context.error.dataHandler = output.result;
                                    reject();
                                }
                            },function(response){
                            context.error.dataHandler = response;
                            reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }

            this.send = new function(){

                this.activate_account = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'activate_account';
                        let call = 'account_activate';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                data: component.form,
                                language: component.$store.getters.language
                            }).then(function(response){
                                let output = response.data[action][call];
                                context.error.dataHandler = null;
                                if(output.result){
                                    resolve(output.token);
                                }else{
                                    context.error.dataHandler = output.result;
                                    reject();
                                }
                            },function(response){
                            context.error.dataHandler = response;
                            reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }
        }    

        let machineConfig = {
            id: 'app',
            context: {
            error: {
                app: null,
                dataHandler: null
            }
            },
            initial: 'idle',
            states: {
                error: {
                    invoke: {
                        src: () => new Promise(()=>{
                            component.snackbar.message = 'activate_account > error > email_not_found';
                            component.snackbar.display = true;
                            setTimeout(function(){
                                component.$router.push('/login')
                            },3000)
                        })
                    }
                },
                idle: {
                    on: {
                        'verify': '#app.verify',
                        'search': '#app.search'
                    }                    
                },
                verify: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            dataHandler.fetch.account(component, context).then(function(user){
                                resolve();
                                if(user && user.validated=='Y'){
                                    component.snackbar.display = true;
                                    component.snackbar.message = 'activate_account > already_validated'
                                    setTimeout(function(){
                                        component.$router.push('/')
                                    },5000)
                                }
                            },function(){
                                reject();
                            })
                        })
                    },
                    on: {
                        'search': '#app.search'
                    }    
                },
                search: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            dataHandler.fetch.user(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: '#activate.start'
                        },
                        onError: {
                            target: '#app.error'
                        }
                    }
                },
                activate: {
                    id: 'activate',
                    initial: 'idle',
                    states: {
                        idle: {},
                        start: {
                            type: 'parallel',
                            // invoke: {
                            //     src: () => new Promise((resolve,reject)=>{
                            //         if(component.account.validated=='N'){
                            //             component.$store.dispatch('language',component.account.language);
                            //             resolve();
                            //         }else{
                            //             reject();
                            //         }
                            //     }),
                            //     onError: {
                            //         target: '#activate.done'
                            //     }
                            // },
                            states: {
                                change_email: {
                                    id: 'change_email',
                                    initial: 'inactive',
                                    states: {
                                        inactive: {},
                                        active: {}
                                    }
                                },
                                change_password: {
                                    id: 'change_password',
                                    initial: 'inactive',
                                    states: {
                                        inactive: {},
                                        active: {}
                                    }
                                }
                            }
                        }
                    }

                },
                activate_account: {
                    initial: 'active',
                    states: {
                        active: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.send.activate_account(component, context).then(function(token){
                                        setTimeout(function(){
                                            component.$store.dispatch('jwt',token)
                                        },3000)
                                        resolve();
                                    },function(){
                                        reject();
                                    })
                                }),
                                onDone: {
                                    target: 'done'
                                },
                                onError: {
                                    target: '#app.error'
                                }
                            }                           
                        },
                        done: {
                            invoke: {
                                src: () => new Promise(()=>{
                                    setTimeout(function(){
                                        component.$router.push('/')
                                    },3000)
                                })
                            } 
                                                
                                        
                        }
                    }
                }
            },
            on: {
                'change_email.active' : '#change_email.active',
                'change_email.inactive' : '#change_email.inactive',
                'change_password.active' : '#change_password.active',
                'change_password.inactive' : '#change_password.inactive',
                'activate_account' : '#app.activate_account'
            }

        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();

        let urlParams = new URLSearchParams(window.location.search);
        let lang = urlParams.get('lang');
        this.lang = lang;
        // this.init();

        switch(this.$route.name){
            case"Verify":
                this.service.send('verify');
            break;

            case"Activate":
                //this.service.send('search');
                this.service.send('verify');
            break;
        }
    },
    data: function(){
        return {
            display: true,
            state: null,
            service: null,
            context: null,
            account: null,
            validVerifyForm: false,
            validActivateForm: false,
            form: {
                id: null,
                email: null,
                firstname: null,
                lastname: null,
                password: null,
                language: null
            },
            snackbar: {
                display: false,
                message: null
            },
            lang: null,
            triggerContactUs: null
        }
    },
    methods: {
        init: function(){
            let self = this;
            if(self.lang){
                setTimeout(function(){
                    self.$store.dispatch('language',self.lang)
                },200)
            }
        },
        setActivateFormValid: function(data){
            this.validActivateForm = data;
        },
        verify: function(){
            this.service.send("search");
        },
        update_form: function(data){
            let form = this.form;
            for(let key in form){
                form[key] = data[key]
            }
        },
        change_email: function(data){
            if(data){
                this.service.send('change_email.active')
            }else{
                this.service.send('change_email.inactive')
            }
        },
        change_password: function(data){
            if(data){
                this.service.send('change_password.active')
            }else{
                this.service.send('change_password.inactive')
            }
        },
        activate_account: function(){
            this.service.send('activate_account')
        }
    },
    computed: {
        logoImage: function(){
          return this.$store.getters.language=='fr' ? LogoFr : LogoEn;
        },
        method: function(){
            return this.$route.name=='Activate' ? 'email' : 'id';
        },
        params: function(){
            return this.$route.params;
        },
        allow: function(){
            let account = this.account;
            let params = this.params;
            let form = this.form;
            let method = this.method;

            if(account){
                if(method=="id"){
                    return account.validation_code == params.validation_code;
                }else if(method=="email"){
                    //return account.email == form.email;                    
                    return account.email == form.email && account.validation_code == params.validation_code;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        allow_activate: function(){
            let form = this.form;
            let firstname = form.firstname && form.firstname.length>0;
            let lastname = form.lastname && form.lastname.length>0;
            let password = form.password && form.password.length>0;
            let state = this.state;
            return firstname && lastname && password && state.activate.start.change_email=='inactive';
        },
        language: function(){
            return this.$store.getters.language;
        }
    },
    watch: {
        state: function(){
            let state = this.state;
            if(state=='activate'){
                if(this.allow && this.account.active=='Y'){
                    this.service.send('activated')
                }
            }
        },
        lang: function(){
            this.init();
        },
        // language: function(){
        //     this.init();
        // }
    }
}
</script>

<style module>
.header {
    color:var(--v-brandBlue-base);
    font-size: 18px;
    line-height: 22px;
    font-weight:600;
    word-break: normal;
}

.content {
    width: 80%;
    margin: 0 auto;
}
</style>

<style lang="css" scoped>
.innerWrapper {
    width: 480px
}

/deep/ .v-btn {
    text-transform: initial;
}

/deep/ .v-dialog {
    box-shadow: none;
}

/deep/ .v-card__title .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 29px !important;
    height: auto !important;
    display: flex!important;
    align-items: center!important;
    padding-right: 2px;
    padding-left: 9px;
}

/deep/ .v-card__title .v-text-field--enclosed .v-input__append-inner {
    margin-top: -3px;
}

/deep/ .v-card__title .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{   
    padding-top: 0;
    padding-bottom: 0;
}

/deep/ .v-card__title .v-select__selection--comma {
    margin-top: 0px;
    margin-bottom: 0px;
}

/deep/ .v-card__title .v-text-field input {
    padding-top: 0;
    padding-bottom: 0;
}
</style>