import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import cookie from './plugins/cookie.js'
import vuetify from './plugins/vuetify';

import commonComponents  from './components/Globals/loader';
commonComponents.forEach(component => {
  Vue.component(component.name, component);
}); 

let methods = {
    labelTerm: function(index=null){
        let lang = this.$store.getters.language;
        let labels = this.$store.getters.library ? this.$store.getters.library.labels : {};
        return labels[index]!==undefined && labels[index][lang]!==undefined ? 
            labels[index][lang]
            : process.env.NODE_ENV == 'development' ? 'Missing ['+ lang +']::'+index
            : '';
    },    
  getRandomInt: function (min, max) {
    min = Math.ceil(min);
    max = Math.floor(max+1);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  },
  unlink: function(obj){
    var object = obj;
    if(typeof obj=='object'){
      object = JSON.stringify(obj);
      object = JSON.parse(object);
    }

    return object;
  },
  parseJSON: function(object){
    for(let key in object){
      var item = object[key];
      if(typeof item=='string' && item.length>=2){
        try{
          item = JSON.parse(item);
          object[key] = item;
        }catch(e){
          var garbage = e;
          garbage = null;
          if(garbage!=null){
            console.log('garbage',garbage);
          }
        }
      }
    }
    return object;
  },
  in_array: function(needle, haystack){
    if( (typeof needle!='undefined') && (typeof haystack!='undefined') ){
      if(typeof needle== 'object'){
        for(let x in needle){
          var hit=false;
          var needleItem=needle[x];
          if(typeof haystack == 'object'){
            for(let z in haystack){
              if(haystack[z]==needleItem){
                hit=true;
                break;
              }
            }
          }
          if(hit){
            break;
          }
        }
        if(hit){return true;}else{return false;}
      }else{
        if(typeof haystack == 'object'){
          var len = haystack.length;
          for(var z=0;z<len;z++){
            if(haystack[z]==needle){
              return true;
            }
          }
          return false;	
        } else {
          return (needle == haystack);
        }			
      }
    }else{
      return false;
    }
  },
  sendRequest: function(data){
    var self = this;
    axios.defaults.headers.post['X-CSRF-Token'] = typeof self.$store.getters.csrf=='string' ? self.$store.getters.csrf : false;
    axios.defaults.headers.post['jwt'] = typeof self.$store.getters.jwt=='string' ? self.$store.getters.jwt : false;
    axios.defaults.headers.post['language'] = typeof self.$store.getters.language=='string' ? self.$store.getters.language : 'en';
    var request = new Promise((resolve)=>{
        resolve()
    }); 
    if(self.$store.getters.csrf || data.action=='csrf'){
      request = new Promise((resolve,reject)=>{
        axios.post(self.$store.getters.api+'?action='+data.action+'&call='+data.call,data).then(function(response){
          if(data.action=='csrf' && (data.call=='request' || data.call=='validate')){
            self.$root.$controller.service.send('session.restart');
            resolve(response);
          }else{
            if(response && response.data && response.data['csrf-check'] && response.data['csrf-check'].result===true){
              self.$root.$controller.service.send('session.restart');
              resolve(response);
            }else{
              console.error('There was a security token issue: a new token was requested and retry was attempted');
              self.$store.dispatch('clear_csrf');
              self.$root.$controller.service.send('csrf.request')
              setTimeout(function(){
                self.$root.sendRequest(data).then(function(response){
                  resolve(response);
                },function(response){
                  reject(response);
                });
              },3000);
              
            }
          }
        })
      })

    }else{
      console.error("NO CSRF")
    }
  
    return request;
  },  
  sendBackgroundRequest: function(data){
    var self = this;
    axios.defaults.headers.post['X-CSRF-Token'] = self.$store.getters.csrf;
    axios.defaults.headers.post['jwt'] = self.$store.getters.jwt;
    var request = new Promise((resolve)=>{
        resolve()
    }); 

    if(self.$store.getters.csrf || data.action=='csrf'){
      request = new Promise((resolve,reject)=>{
        axios.post(self.$store.getters.api+'?action='+data.action+'&call='+data.call,data).then(function(response){
          if(response.data['csrf-check'].result===true){
            self.$root.$controller.service.send('session.restart');
            resolve(response);
          }else{
            reject(response);
          }
        })
      })
    }else{
      console.log("sendBackgroundRequest","NO CSRF")
    }

  
    return request;
  },
  sendFile: function(data){
    var self = this;
    axios.defaults.headers.post['X-CSRF-Token'] = self.$store.getters.csrf;
    axios.defaults.headers.post['jwt'] = self.$store.getters.jwt;
    var request = new Promise((resolve)=>{
        resolve()
    }); 

    request = new Promise((resolve,reject)=>{

      axios.post(self.$store.getters.api+'?action=uploadFile',data,{
        onUploadProgress: uploadEvent => {
          self.$store.dispatch('progressBar',Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      }).then(function(response){
        if(response.data['csrf-check'].result===true){
          self.$root.$controller.service.send('session.restart');
          resolve(response);
        }else{
          reject(response);
        }

      })
    })
  
    return request;
  },
  downloadFile: function(data){
    // var self = this;
    // var request = new Promise((resolve)=>{
    //   axios.get(self.$store.getters.download_handler, data)
    //   .then(function (response) {
    //     console.log('downloadFile',response);
    //     resolve();
    //   })        
    // });
    var self = this;
    var request = new Promise((resolve)=>{
      axios.request({
        url: self.$store.getters.download_handler,
        method: 'get',
        headers: {
          'X-CSRF-Token': typeof self.$store.getters.csrf=='string' ? self.$store.getters.csrf : false,
          'jwt': typeof self.$store.getters.jwt=='string' ? self.$store.getters.jwt : false,
          'language': typeof self.$store.getters.language=='string' ? self.$store.getters.language : 'en'
        },
        params: data.params,
        responseType: 'blob'
      }).then(function(response){
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', data.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        self.$root.$controller.service.send('session.restart');
        resolve();
      })
    });
    return request;
  },
  streamFile: function(data){
    // var self = this;
    // var request = new Promise((resolve)=>{
    //   axios.get(self.$store.getters.download_handler, data)
    //   .then(function (response) {
    //     console.log('downloadFile',response);
    //     resolve();
    //   })        
    // });
    var self = this;
    var request = new Promise((resolve)=>{
      axios.request({
        url: self.$store.getters.download_handler,
        method: 'get',
        headers: {
          'X-CSRF-Token': typeof self.$store.getters.csrf=='string' ? self.$store.getters.csrf : false,
          'jwt': typeof self.$store.getters.jwt=='string' ? self.$store.getters.jwt : false,
          'language': typeof self.$store.getters.language=='string' ? self.$store.getters.language : 'en'
        },
        params: data.params,
        responseType: 'blob'
      }).then(function(response){
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        self.$root.$controller.service.send('session.restart');
        resolve(downloadUrl);
      })
    });
    return request;
  },
  objectLength: function(object){
    var counter = 0;
    for(let i in object){
      typeof i;
      counter++;
    }
    return counter;
  },
  allTrue: function(object){
    var allTrue = true;
    for(let i in object){
        if(object[i]===false){
            allTrue = false
            break;
        }
    }
    return allTrue;
  },
  proper(str){

    str = str.toLowerCase().split(' ');
 
    let final = [ ];
 
     for(let  word of str){
       final.push(word.charAt(0).toUpperCase()+ word.slice(1));
     }
 
   return final.join(' ')
  },
  baseURL: function() {
    return this.$store.getters.baseURL;
  },
  windowSize: function() {
    return this.$store.getters.windowSize;
  },
  formRules: function(email=null, initialPw=null) {
    function validate_password_strength(password){
      var pass = password!=null ? isStrongPwd(password) : false;

      function isStrongPwd(password) {
      
          var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      
          var lowercase = "abcdefghijklmnopqrstuvwxyz";
      
          var digits = "0123456789";
      
          var splChars ="!@#$%&*()-";
      
          var ucaseFlag = contains(password, uppercase);
      
          var lcaseFlag = contains(password, lowercase);
      
          var digitsFlag = contains(password, digits);
      
          var splCharsFlag = contains(password, splChars);

          var lengthFlag = password.length>=6;

          return (lengthFlag && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag)
      
      }
      
      function contains(password, allowedChars) {
      
          for (var i = 0; i < password.length; i++) {
      
                  var char = password.charAt(i);
      
                  if (allowedChars.indexOf(char) >= 0) { return true; }
      
              }
      
          return false;
      }

      if(pass===false){
          // this.dialog = true;
      }
      return pass
    }

    var rules = {
      validFirstName: [
        v => !!v || 'messages > required firstname'
      ],
      validLastName: [
        v => !!v || 'messages > required lastname'
      ],
      validEmail: [
        v => !!v || 'messages > required email',
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)
            || 'messages > invalid email'
      ],
      validConfirmEmail: [
        v => !!v || 'messages > required confirmEmail',
        v => v === email || 'messages > notMatch emails'
      ],
      validProvince: [
        v => !!v || 'messages > required province'
      ],
      validPostalCode: [
        v => !!v || 'messages > required postalCode',
        v => /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || 'messages > invalid postalcode'
      ],
      validLanguage: [
        v => !!v || 'messages > required preferLanguage'
      ],
      validLoginPassword: [
        v => !!v || 'messages > required password'
      ], 
      validPassword: [
        v => !!v || 'messages > required password',
        v => validate_password_strength(v) || "messages > weak password"
      ],
      validConfirmPassword: [
        v => !!v || 'messages > required confirmPassword',
        v => v === initialPw || 'messages > notMatch passwords'
      ],
      validSubject: [
        v => !!v || 'messages > required subject'
      ],
      validProgram: [
        v => !!v || 'messages > required program'
      ],
      validTitle: [
        v => !!v || 'messages > required title'
      ],
      validMessage: [
        v => !!v || 'messages > required contactUsMsg'
      ],
      validSpecialty: [
        v => !!v || 'messages > required specialty'
      ],
      validConsent: [
        v => v=='Y' || 'message > required consent'
      ],
      validProfession: [
        v => !!v || 'messages > required profession'
      ]
    }
    // console.log(email, initialPw);
    return rules
  },
  validEmail: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email) {
        return re.test(email);  
    }
    return false;
  },
  validLoginPassword: function(password) {
      if (password) {
          return true;
      } 
      return false;
  }, 
}

function guard(to, from, next){
  var user = store.getters.user;
  var matched = to.matched;
  var requiresAuth = false;
  var restrict_type = false;
  var allow_type = true;
  var triggeredBy = null;

  for(var i=0; i<matched.length; i++){
    var route = matched[i];

    if(typeof route.meta.requiresAuth!='undefined' && requiresAuth===false){
      requiresAuth = route.meta.requiresAuth;
      restrict_type = (route.meta && route.meta.allow) ? true : false;
      if(requiresAuth===true){
        triggeredBy = route;
      }
    }
  }

  if(restrict_type && user){
    allow_type = methods.in_array(user.type,triggeredBy.meta.allow);
  }

  if(requiresAuth===false || (requiresAuth===true && user && allow_type)){
    next();
  }else{
    // console.log('blocking: '+triggeredBy.path,{
    //   requiresAuth: requiresAuth,
    //   user: user,
    //   allow_type: allow_type,
    //   triggeredBy: triggeredBy
    // })
    store.dispatch('entryURL',{path:to.fullPath , router:router})
    next('/login');
  }
}

router.beforeEach(guard);


Vue.config.productionTip = false

Vue.mixin({
  methods: methods
});

let code = new Vue({
  router,
  store,
  vuetify,
  created: function(){
    this.init();
  },
  methods: {
    init: function(){
      Vue.prototype.cookie = cookie;      
    }
  },
  render: h => h(App)
}).$mount('#app')

if(location.hostname=='localhost'){
  window.code = code;
}else{
  code = null;
}
