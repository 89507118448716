<template>
<v-form ref="passwordForm" v-model="validForm" v-on:submit.prevent='onEnter'>
    <v-btn small v-if="state=='idle'" @click="service.send('change_password')" color="var(--v-brandOrangeAccent1-base)"><str :index="'button > change_password'"/></v-btn>

    <template v-else>
        <template v-if="state=='change_password'">
            <v-text-field v-if="state=='change_password' || state=='verify_password'" type="password" v-model="old_password" outlined dense :label="labelTerm('form > current_password')">
                <template v-slot:message="{ message }"><str :index="message" /></template>
            </v-text-field>
            <v-row>
                <v-col cols="12" class="d-flex justify-space-around">
                    <v-btn small :disabled="!old_password || old_password.length<6" @click="service.send('verify_password')" color="warning"><str :index="'form > validate_password'"/></v-btn>
                    <v-btn v-if="!admin && !force_change" small @click="service.send('cancel')" color="var(--v-brandOrangeAccent1-lighten1)"><str :index="'button > cancel'"/></v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-else>
            <v-text-field type="password" v-model="new_password" outlined dense required :rules="formRules().validPassword" validate-on-change :label="labelTerm('form > new_password')">
                <template v-slot:message="{ message }"><str :index="message" /></template>
            </v-text-field>
            <v-text-field type="password" v-model="confirm_password" outlined dense :disabled="!new_password || (new_password && new_password.length<6)" required :rules="formRules().validPassword" validate-on-change :label="labelTerm('form > confirm_password')">
                <template v-slot:message="{ message }"><str :index="message" /></template>            
            </v-text-field>
            <template v-if="validForm && confirm_password != new_password">
                <div style="margin-top: -10px">
                    <str :index="'messages > notMatch passwords'"/>
                </div>
            </template>
            <br>

            <!-- on Account activation route form validated on match without explicit Accept button click. -->
            <v-row>
                <v-col cols="12" class="d-flex justify-space-around">
                    <v-btn v-if="autoaccept_on_match===false" :disabled="!validForm || !matched" @click="service.send('accept')" small color="warning"><str :index="'button > submit var1'"/></v-btn>
                    <v-btn v-if="!admin && !force_change" small @click="service.send('cancel')" color="var(--v-brandOrangeAccent1-lighten1)"><str :index="'button > cancel'"/></v-btn>
                </v-col>
            </v-row>
        </template>           
    </template>

    <v-snackbar v-model="snackbar.display">
        <str v-if="snackbar.message" :index="snackbar.message"/>
    </v-snackbar>
</v-form>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    props: {
        admin: {
            type: Boolean,
            required: false,
            default: false
        },
        force_change: {
            type: Boolean,
            required: false,
            default: false
        },
        autoaccept_on_match: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created: function(){
        let component = this;

        const comms = new function(){

            this.verify_password = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'forms';
                    let call = 'verify_password';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            user_id: component.$store.getters.user.id,
                            password: component.old_password
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve();
                            }else{
                                reject(response.data[action][call].error);
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

        }    

        let machineConfig = {
            id: 'app',
            context: {
                error: null
            },
            initial: 'idle',
            states: {
                idle: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            if (component.autoaccept_on_match!==undefined && component.autoaccept_on_match===true
                                && component.new_password!==null && component.confirm_password!==null
                                && component.new_password.length>0 && component.confirm_password.length>0){
                                // this covers Account activate scenario - so values won't reset. do nothing                           
                            }
                            else {
                                component.new_password = null;
                                component.confirm_password = null;
                                component.old_password = null;
                            }
                            if(component.admin || component.force_change){
                                resolve()
                            }
                        }),
                        onDone: {
                            target: 'new_password'
                        }
                    },
                    on: {
                        'change_password' : 'change_password'
                    }
                },
                change_password: {
                    on: {
                        'verify_password' : 'verify_password'
                    }
                },
                verify_password: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            comms.verify_password(component, context).then(function(){
                                component.snackbar.message = 'message > account_validated'
                                component.snackbar.display = true;
                                resolve();
                            },function(error){
                                component.snackbar.message = error;
                                component.snackbar.display = true;
                                reject();
                            })
                        }),
                        onDone: {
                            target: 'new_password'
                        },
                        onError: {
                            target: 'change_password'
                        }
                    }
                },
                new_password: {
                    on: {
                        accept: 'accept'
                    }
                },
                accept: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            component.$emit('update',component.new_password);
                            resolve();
                        }),
                        onDone: {
                            target: 'idle'
                        }
                    }
                },
                cancel: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            component.$emit('update',null);
                            resolve();
                        }),
                        onDone: {
                            target: 'idle'
                        }
                    }
                }
            },
            on: {
                'cancel':'cancel'
            }
        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();         
    },
    data: function(){
        return {
            state: null,
            context: null,
            service: null,
            validForm: false,
            old_password: null,
            new_password: null,
            confirm_password: null,
            snackbar: {
                display: false,
                message: null
            }
        }
    },
    methods: {
        onEnter: function(){
            switch(this.state){
                case"change_password":
                    this.service.send('verify_password');
                break;

                case"new_password":
                    if(this.validForm && this.new_password==this.confirm_password){
                        this.service.send('accept');
                    }
                break;
            }
        }
    },
    computed: {
        matched: function(){
            return this.validForm && (this.new_password == this.confirm_password);
        }
    },
    watch: {
        state: function(){
            this.$emit('state_change',this.state);
        },
        matched: function(){
            if(this.new_password==this.confirm_password){
                if (this.autoaccept_on_match===true){
                    this.service.send('accept');
                }else{
                    // this.service.send('cancel');
                }
            }
        },
        // validForm: function(after, before){ // eslint-disable-line no-unused-vars
        //     console.log('watch > validform',{
        //         after: after,
        //         match: (this.new_password==this.confirm_password),
        //         autoaccept_on_match: this.autoaccept_on_match===true
        //     })
        //     if (after===true){
        //         if (this.autoaccept_on_match===true && this.new_password==this.confirm_password){
        //             this.service.send('accept');
        //         }
        //         else if (this.autoaccept_on_match===true){
        //             return this.service.send('cancel');
        //         }
        //     }
        //     else if (this.autoaccept_on_match===true){
        //         this.service.send('cancel');
        //     }
        // }        
    }
}
</script>

<style>

</style>