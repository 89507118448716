<template>
<div>
404
</div>
</template>

<script>
export default {
    mounted: function(){
        this.$controller.service.send('ui.login.hide')
    }
}
</script>

<style>

</style>