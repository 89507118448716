<template>
<div>
    <component v-bind:is="tab" :debug="debug" :programs="programs" :language="language"/>
</div>
</template>

<script>
import UserManagement from '@/components/Admin/UserManagement.vue'
import ContentManagement from '@/components/Admin/ContentManagement.vue'
import DataDashboard from '@/components/Admin/DataDashboard.vue'

export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        language: {
        type: String,
        required: true
        },
        tab: {
            type: String,
            required: true
        },
        programs: {
            type: Array,
            required: true
        }
    },
    components: {
        UserManagement,
        ContentManagement,
        DataDashboard        
    }
}
</script>

<style>

</style>