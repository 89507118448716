import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                background: '#F0F2F5',
                brandWhite: '#FFFFFF',
                brandBlue: '#1C75BC',
                brandBlack: '#000000',
                brandOrange: '#F47A27',
                borderInputControl: '#BDBDBD',
                borderModal: '#707070',
                brandGray1: '#8F8F8F',
                brandGray2: '#7B7B7B', //homepage CTA icons
                brandGray3: '#8C8C8C', //separate line
                backgroundGrayAccent1: '#E0E3E8', //side panel
                brandBlueAccent1: '#2C98B6', //Links
                brandBlueAccent2: '#0E89AC', //User Icon Initials
                brandBlueAccent3: '#F8FCFF', // Admin - user details background
                brandOrangeAccent1: '#FFBA8B', //disabled,non-active CTA/Buttons
                brandOrangeAccent2: '#F57E20', //H3
                brandBlueCTA: '#3375B7' //CTA/Buttons
            },
            light: {
                background: '#F0F2F5',
                brandWhite: '#FFFFFF',
                brandBlue: '#1C75BC',
                brandBlack: '#000000',
                brandOrange: '#F47A27',
                borderInputControl: '#BDBDBD',
                borderModal: '#707070',
                brandGray1: '#8F8F8F',
                brandGray2: '#7B7B7B', //homepage CTA icons
                brandGray3: '#8C8C8C', //separate line
                backgroundGrayAccent1: '#E0E3E8', //side panel            
                brandBlueAccent1: '#2C98B6', //Links,
                brandBlueAccent2: '#0E89AC', //User Icon Initials
                brandBlueAccent3: '#F8FCFF', // Admin - user details background
                brandOrangeAccent1: '#FFBA8B', //disabled,non-active CTA/Buttons
                brandOrangeAccent2: '#F57E20', //H3
                brandBlueCTA: '#3375B7' //CTA/Buttons                
            }       
        }, 
        options: {
            customProperties: true
        }    
    }  
});
