<template>
<v-card class="px-15 pt-11">
    <div :class="$style.closePrivacyBtnWrapper">
        <v-icon color="black" :class="$style.closePrivacyBtn" @click="$emit('close')">mdi-close-circle</v-icon>
    </div>
    
    <v-tabs 
            v-model="tab"
            >
            <v-tabs-slider style="margin-top: -5px; height: 4px;"></v-tabs-slider>
            <v-tab  :key="0">
                    <str index="site_regulation > privacy policy" />
            </v-tab>
            <v-tab :key="1">
                    <str index="site_regulation > terms of use" />
            </v-tab>

            <v-tabs-items v-model="tab">
                    <v-tab-item>
                            <iframe class="wdoc" :src="`https://docs.google.com/gview?url=${doc_hub_url()}/${langPrefix()}_PrivacyPolicy.docx&embedded=true`"></iframe>
                    </v-tab-item>
                    <v-tab-item>
                        <component :is="docHtmJanssenTermsofUse()" class="wdoc" @switch_tab="switchTab"/>
                    </v-tab-item>
            </v-tabs-items>              
    </v-tabs>

    <v-card-actions class="pb-5">
        <v-spacer/>
            <v-btn elevation='0' dark color="var(--v-brandOrange-base)" small style="font-size:18px;" @click="$emit('close')"><str index="button > close"/></v-btn>
        <v-spacer/>
    </v-card-actions>
</v-card>
</template>

<script>
import En_JanssenTermsofUse from '@/components/SiteRegulation/EN_JanssenTermsofUse.vue'
import Fr_JanssenTermsofUse from '@/components/SiteRegulation/FR_JanssenTermsofUse.vue'

export default {
    components: {
        En_JanssenTermsofUse,
        Fr_JanssenTermsofUse
    },
    props: {
        language: {
            type: String,
            required: true
        },
        target: {
            type: String,
            default: 'privacy-policy'
        }
    },
    created: function(){
        this.tab = this.target==='privacy-policy' ? 0 : 1;
    },
    data: function(){
        return {
            tab: 0
        }
    },
    methods: {
        doc_hub_url: function(){
            return this.$store.getters.doc_hub_url;
        },
        langPrefix: function(){
            return this.language.toUpperCase();
        },
        docHtmJanssenTermsofUse: function(){
            return this.language==='fr' ? Fr_JanssenTermsofUse : En_JanssenTermsofUse;
        },
        switchTab: function(tabIndex){
            return this.tab=tabIndex;
        }
    },
    watch: {
        target: function(after, before){ // eslint-disable-line no-unused-vars
                switch(after) {
                    case "privacy-policy":
                      this.tab=0;
                      break;
                    case "terms-of-use":
                      this.tab=1;
                      break;                  
                }
        }
    }
}
</script>

<style module>

.closePrivacyBtnWrapper {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 10;
}

.closePrivacyBtn {
    margin-right: 10px;
    font-size: 30px !important;
}

.policyContentWrapper {
    overflow-y: auto;
}
</style>

<style language="css" scoped>
.wdoc {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
}


@media only screen and (max-width: 600px){
    .wdoc {
        height: self;
    }
    
    .closePrivacyBtnWrapper {
        right: 10%
    }
}


/deep/ .v-btn {
    text-transform: initial;
}

/deep/ .v-tab {
    text-transform: initial;
    font-size:25px;
    color: var(--v-brandBlue-base);
    padding: 0;
    margin-right: 32px;    
}

/deep/ .v-tabs-slider {
    background: var(--v-brandBlue-base);
    margin-top: -5px;
    padding-top: 3px;    
}
</style>