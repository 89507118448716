import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Activate from '../views/Activate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login'
  },
  {
    path: '/activate/:user_id/:validation_code',
    name: 'Activate',
    component: Activate,
    /*
    children: [
      {
        path: '/activate/:user_id/:validation_code',
        name: 'Activate',
        component: Activate,  
      }
    ]
    * 
    */
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
