<template>
<div class="text-center" style="color: black;">
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                width="31"
                height="31"
                color="var(--v-brandWhite-base)"
                class="brandBlueAccent2--text"
                elevation="0"
                rounded
                v-bind="attrs"
                v-on="on"
                style="border: 1px solid var(--v-brandBlue-base) !important; text-transform: uppercase;"
                >
                {{$initials}}
            </v-btn>
        </template>

        <v-list>
            <v-list-item :class="$style.menu_item">
                <v-list-item-title @click="popups.editProfile=true">
                    <v-icon>mdi-account-edit</v-icon>
                    <str :index="'user_icon > edit profile'"/>
                </v-list-item-title>
            </v-list-item>
            
            <v-list-item :class="$style.menu_item" @click="$emit('logout')">
                <v-list-item-title>
                    <v-icon>mdi-logout</v-icon>
                    <str :index="'user_icon > logout'"/>
                </v-list-item-title>
            </v-list-item>
        </v-list>


    </v-menu>

    <v-dialog v-model="popups.editProfile" max-width="1024">
        <v-card v-if="popups.editProfile">
            <v-btn style="position:absolute; top:0px; right: 0px;" @click="popups.editProfile=false" text><v-icon style="font-size:30px">mdi-close-circle</v-icon></v-btn>
            <v-card-title class="justify-center brandBlue--text headline" style="font-weight: 500; font-size: 20px; line-height: 24px;"><str :index="'user_icon > edit profile'"/></v-card-title>
            <v-card-text>
                <EditProfile :user="user"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import EditProfile from './editProfile.vue'
export default {
    components: {
        EditProfile
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        navTrigger: {
            type: Boolean,
            default: false
        }       
    },
    data: function(){
        return {
            popups: {
                editProfile: false
            }
        }
    },
    computed: {
        $initials: function(){
            let user = this.user;
            return user.firstname.substr(0,1) + user.lastname.substr(0,1);
        }
    },
    watch: {
        navTrigger: function(after, before){
            if (after===true && after!==before){
                this.popups.editProfile=after;
                this.$emit("triggered")
            }
        }
    }            
}
</script>

<style module>
.menu_item{
    min-width: 200px;
    cursor: pointer;
}
    .menu_item:hover{
        background-color: lightgrey;
    }

.menu_item i{
    margin: 25px;
},

.h3Heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color:var(--v-brandBlue-base) !important;
}

.h3HeadingMobile {
    font-size: 20px !important;
    line-height: 24px !important;
}
</style>