<template>
<v-card width="100%" class="pt-2" style="max-width:220px; height:240px">
    <v-img :src="member.image" contain height="90"/>
    <v-card-title class="justify-center" style="font-weight:600">
        {{member.name[language]}}
    </v-card-title>
    
    <v-card-subtitle class="text-center py-0" style="height:50px">
        {{member.credentials[language]}}
    </v-card-subtitle>
    <v-card-subtitle class="text-center py-0">
        {{member.origin[language]}}
    </v-card-subtitle>    
</v-card>
</template>

<script>

export default {
    props: {
        member: {
            type: Object,
            required: true
        }
    },
    computed: {
        language: function(){
          return this.$store.getters.language
        }
    }
}
</script>

<style>

</style>