<template>
<div>
    <v-dialog 
        v-model="display" 
        persistent
        no-click-animation
        :fullscreen="windowSize().x <= 960 || windowSize().y <= 630"
        width='600'
        hide-overlay
        >      
        <v-container mt-0 background="var(--v-background-base)">
            <v-row justify="center">
                <v-img
                  alt="logo"
                  class="shrink"
                  contain
                  :src="logoImage"
                  transition="scale-transition"
                  width="270"
                  style="cursor: pointer;"
                />
            </v-row>
        </v-container>
        <v-card class="rounded-lg" style="border: 1px solid var(--v-background-darken1);">
            <div v-if="view=='forgot_password'" :class="$style.closeBtnWrapper">
                <v-icon @click="service.send('login');" style="font-size:30px">mdi-close-circle</v-icon>
            </div>
            
            <v-card-title class="justify-center pt-0">
                <ContactIcon class="mt-2"/>
                <div id="login-header" class="ml-1">
                    <LanguageSelect style="min-width:90px;max-width:120px;width:90px;font-size:12px;line-height:14px"/>
                </div>
            </v-card-title>
            <v-card-title class="pt-1">
                <v-spacer/>
                    <h3 v-if="view=='login'" style="margin: 0 auto; color:var(--v-brandBlue-base); font-size: 18px; line-height: 22px; font-weight:600; word-break: normal;">
                        <str :index="'login > title'"/>
                    </h3>
                    <h3 v-if="view=='forgot_password'" style="margin: 0 auto; color:var(--v-brandBlue-base); font-size: 18px; line-height: 22px; font-weight:600; word-break: normal;">
                        <str :index="'forgot_password > title'"/>
                    </h3>
                <v-spacer/>
            </v-card-title>
            <v-card-title v-if="view=='login'" class="pt-2">
                <v-spacer/>
                    <h3 style="margin: 0 auto; color:var(--v-brandBlack-base); font-size: 22px; line-height: 26px; font-weight:600"><str :index="'button > sign_in'"/></h3>
                <v-spacer/>
            </v-card-title>
            <v-card-text>
                <fieldset v-if="debug">
                    <legend>State: {{state}}</legend>
                </fieldset>


                <v-container pt-1 :class="$store.getters.language==='fr'? $style.expandWidth : $style.regularWidth">
                    <template v-if="!$store.getters.user">
                        <v-row justify="center">
                            <!-- <v-col cols="12" sm="1"></v-col> -->
                            <v-col cols="12" lg="12" class="py-0">
                                <v-tabs-items v-model="view">

                                    <v-tab-item value="login">
                                        <v-card>
                                            <!-- placeholder for login error noti displayed to user -->
                                            <v-card-text class="error--text ma-0 pa-0" style="height:16px">
                                                <template v-if="debug">
                                                    {{context.error}}
                                                </template>
                                                <template v-else>
                                                    <str v-if="context.error && context.error.foundUser===false" :index="'message > auth foundUser'"/>
                                                    <str v-else-if="context.error && context.error.matchPassword===false" :index="'message > auth incorrectPassword'"/>
                                                    <str v-else-if="context.error" :index="'message > auth matchPassword'"/>
                                                </template>
                                            </v-card-text>                                        
                                            <v-form id="loginForm" ref='loginForm' v-model="loginForm" v-on:submit.prevent='login'>
                                                <button></button>
                                                <v-text-field type="email" id="login_email" v-model="email" required :rules="formRules().validEmail" dense outlined validate-on-change @change="handle_remember_me; email = email.toLowerCase();" :label="labelTerm('form > email')">
                                                    <v-icon slot="append">
                                                        mdi-account
                                                    </v-icon>
                                                    <template v-slot:message="{ message }">
                                                        <str :index="message" />
                                                    </template>                                                 
                                                </v-text-field>


                                                <v-text-field 
                                                    :type="config.passwordType"  
                                                    id="password"
                                                    v-model="password"
                                                    dense
                                                    outlined 
                                                    :label="labelTerm('form > password')" 
                                                    required 
                                                    :rules="formRules().validLoginPassword" 
                                                    validate-on-change>
                                                    <v-icon slot="append" @click="togglePasswordType" v-if="config.passwordType != 'password'">
                                                        mdi-eye
                                                    </v-icon>
                                                    <v-icon slot="append" @click="togglePasswordType" v-else>
                                                        mdi-eye-off
                                                    </v-icon>
                                                    <template v-slot:message="{ message }">
                                                        <str :index="message" />
                                                    </template>                                                
                                                </v-text-field>
                                            </v-form>
                                            <v-container id="login-complimentary" px-0 pt-0>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" class="d-inline-flex pt-0" style="text-align: left;">
                                                        <v-checkbox class="mt-0 pt-0" v-model="remember_me" style="font-size:12px;" @change="handle_remember_me"/>
                                                        <str style="font-size:13px !important;" :index="'form > remember'"/>
                                                    </v-col>

                                                    <v-col 
                                                        :class="[$vuetify.breakpoint.smAndDown ? ['text-left', 'pl-4']: ['justify-end', 'px-0'], 'brandBlueAccent1--text', 'pt-0', 'd-inline-flex']" 
                                                        cols="12" lg="6" md="6" style="text-decoration: underline; margin-top: -3px">
                                                        <v-btn 
                                                            :class="['mt-0', 'pt-0', 'pr-3', 'brandBlueAccent1--text', {['pl-0']:$vuetify.breakpoint.smAndDown}]" text link 
                                                            @click="service.send('forgot_password')" style="font-size:13px; margin-top: -5px !important;"
                                                            >
                                                            <str :index="'button > forgot_password'"/>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                       
                                        
                                        
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn min-width="130" color="var(--v-brandOrange-base)" id="login" @click="login" :disabled="loginForm===false" style="font-size:18px;" :dark="loginForm===true"><str :index="'button > sign_in'"/></v-btn>
                                                <v-spacer/>
                                            </v-card-actions>
                                        </v-card>
                                    </v-tab-item>

                                    <!-- Forgot Password !-->
                                    <v-tab-item value="forgot_password" class="pt-3">
                                        <v-container id="forgot-pwd" px-0>
                                            <v-row align="center" >
                                                <v-col cols="12" class="d-inline-block py-0">
                                                    <v-form ref="forgot_password_form" v-model="valid_form_email_forgot_password" v-on:submit.prevent='`return false;`'>
                                                        <div style="width:90%;margin:0 auto;">
                                                            <p :class="$style.forgetLabel"><str index="forgetPw > label email" /></p>
                                                            <v-text-field type="text" id="forgot_email" v-model="email" required :rules="formRules().validEmail" dense outlined validate-on-change>
                                                                <v-icon slot="append">mdi-account</v-icon>
                                                                <template v-slot:message="{ message }">
                                                                    <str :index="message" />
                                                                </template>
                                                                <v-btn slot="append-outer" dense small dark @click="send_code();"
                                                                    :color="valid_form_email_forgot_password ? 'var(--v-brandOrange-base)' : 'var(--v-brandOrangeAccent1-base)'"
                                                                    >
                                                                        <str v-if="forgotPwdCounter==1" :index="'button > send'"/>
                                                                        <str v-else-if="forgotPwdCounter>1" :index="'button > resend'"/>
                                                                </v-btn>
                                                            </v-text-field>
                                                        </div>
                                                    </v-form>
                                                    </v-col>
                                                
                                            </v-row>

                                                
                                            <v-row align="center" >
                                                <v-col cols="12" class="d-inline-block py-0">
                                                    <div style="width:90%;margin:0 auto;">
                                                        <p :class="$style.forgetLabel"><str index="form > verification_code"/></p>
                                                        <v-text-field type="text" id="verfication_code" :disabled="!valid_form_email_forgot_password || verification_code_valid===true" v-model="verfication_code" dense outlined>
                                                            <v-icon slot="append">mdi-barcode</v-icon>
                                                            <v-icon v-if="verification_code_valid===true" slot="append-outer" color="success">mdi-check</v-icon>
                                                            <template v-slot:message="{ message }">
                                                                <str :index="message" />
                                                            </template>                                                                       
                                                        </v-text-field>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <v-row align="center" >
                                                <v-col cols="12" class="d-inline-block py-0">
                                                    <v-form ref="passwordForm" v-model="validPasswordForm" v-on:submit.prevent='onEnter'>
                                                        <div style="width:90%;margin:0 auto;">
                                                            <p :class="$style.forgetLabel"><str index="form > new_password"/></p>                                                
                                                            <v-text-field type="password" v-model="new_password" :disabled="verification_code_valid===false" outlined dense required :rules="formRules().validPassword" validate-on-change>
                                                                <v-icon slot="append">mdi-key-variant</v-icon>
                                                                <v-icon v-if="validPasswordForm && new_password===confirm_password" slot="append-outer" color="success">mdi-check</v-icon>
                                                                <template v-slot:message="{ message }"><str :index="message" /></template>
                                                            </v-text-field>
                                                            <p :class="$style.forgetLabel"><str index="form > confirm_password"/></p>
                                                            <v-text-field type="password" v-model="confirm_password" outlined dense :disabled="!new_password || (new_password && new_password.length<6)" required :rules="formRules().validPassword" validate-on-change>
                                                                <v-icon slot="append">mdi-key-variant</v-icon>
                                                                <v-icon v-if="validPasswordForm && new_password===confirm_password" slot="append-outer" color="success">mdi-check</v-icon>            
                                                                <template v-slot:message="{ message }"><str :index="message" /></template>            
                                                            </v-text-field>
                                                            <template v-if="validPasswordForm && confirm_password !== new_password">
                                                                <div style="margin-top: -10px">
                                                                    <str :index="'messages > notMatch passwords'"/>
                                                                </div>
                                                            </template>                                                

                                                            <v-btn min-width="130" dark style="left: 50%; transform:translateX(-50%);"
                                                                @click="service.send('forgot_password.change_password')"
                                                                :color="validPasswordForm && new_password===confirm_password ? 'var(--v-brandOrange-base)' : 'var(--v-brandOrangeAccent1-base)'"
                                                                >
                                                                    <str index="button > done"/>
                                                            </v-btn>
                                                        </div>
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-container>    
                                    </v-tab-item>
                                    
                                </v-tabs-items>
                            </v-col>
                            <!-- <v-col cols="12" sm="1"></v-col> -->
                        </v-row>
                    </template>

                    <v-row v-else>
                        <v-col cols="12" sm="12">
                            <v-btn @click="logout"><str :index="'button > sign_out'"/></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <!-- <v-card-actions>
                <v-spacer/>
                    <v-btn @click="service.send('forgot_password')">forgot_password</v-btn>
                <v-spacer/>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display">
        <span v-html="snackbar.message"/>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import LanguageSelect from '@/components/UI/LanguageSelect.vue'
import LogoEn from '@/assets/logos/adhd_hub_en.png'
import LogoFr from '@/assets/logos/adhd_hub_fr.png'
import ContactIcon from '@/components/UI/ContactIcon.vue'

export default {
    components: {
        LanguageSelect,
        ContactIcon        
    },
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    created: function(){
        this.display = this.show===true;
        if(this.cookie && this.cookie.get('email')){
            this.email = this.cookie.get('email');
            this.remember_me = true;
        }else{
            this.remember_me = false;
        }

        let component = this;

        const comms = new function(){

            this.login = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'authenticate';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            email: component.email,
                            password: component.password
                        }).then(function(response){
                            let token = response.data[action][call].jwt;
                            if(token){
                                component.$store.dispatch('jwt',token);
                                context.error = null;
                                resolve();
                            }else{
                                context.error = response.data[action][call].results;
                                reject();
                            }
                        },function(response){
                            context.error = response.data;
                            component.$store.dispatch('clear_jwt');
                            reject();
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.logout = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'logout';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call
                        }).then(function(){
                            component.$store.dispatch('clear_jwt');
                            resolve();
                        },function(response){
                            context.error = response
                            reject();
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.send_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'send_code';
                    
                    if (component.forgotPwdCounter>1){
                        this.reset_forgot_password_values(component, context);
                    }

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            email: component.email,
                            language: component.$store.getters.language
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve();
                            }else{
                                reject();
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.validate_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'validate_code';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            reset_code: component.verfication_code,
                            email: component.email
                        }).then(function(response){
                            if(response.data[action][call].result===true){
                                resolve()
                            }else{
                                reject()
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.change_password = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'change_password';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,                            
                            email: component.email,
                            password: component.new_password,
                            reset_code: component.verfication_code,
                            language: component.$store.getters.language
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve()
                            }else{
                                reject()
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }
            
            this.reset_forgot_password_values = function(component, context){ // eslint-disable-line no-unused-vars
                component.verfication_code = null;
                component.verification_code_valid = false;
                component.new_password = null;
                component.validPasswordForm = false;
                component.confirm_password = null;
            }
        }    

        let machineConfig = {
            id: 'login',
            context: {
                error: null
            },
            initial: 'login',
            states: {
                login: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            component.verfication_code = null;
                            component.verification_code_valid = false;
                            component.new_password = null;
                            component.validPasswordForm = false;
                            component.confirm_password = null;
                            component.forgotPwdCounter = 1;
                            resolve();
                        })
                    }
                },
                forgot_password: {
                    initial: 'ready',
                    states: {
                        ready: {
                            on: {
                                'forgot_password.send_code' : 'send_code',
                                'forgot_password.validate_code' : 'validate_code'
                            }
                        },
                        send_code: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.send_code(component, context).then(function(){
                                        component.snackbar.message = component.labelTerm('message > password resetEmail');
                                        component.snackbar.display = true;
                                        component.forgotPwdCounter++;
                                        resolve();
                                    },function(){
                                        component.snackbar.message = component.labelTerm('message > auth foundUser');
                                        component.snackbar.display = true;
                                        reject();
                                    })
                                }),
                                onDone: {
                                    target: 'ready'
                                }
                            }
                        },
                        validate_code: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.validate_code(component, context).then(function(){
                                        component.verification_code_valid = true;
                                        component.forgotPwdCounter++;
                                        resolve();
                                    },function(){
                                        component.verification_code_valid = false;
                                        component.snackbar.message = component.labelTerm('message > invalid code');
                                        component.snackbar.display = true;
                                        reject();
                                    })
                                }),
                                onDone: {
                                    target: 'reset_password'
                                },
                                onError: {
                                    target: 'ready'
                                }
                            }
                        },
                        reset_password: {
                            on: {
                                'forgot_password.change_password' : 'change_password'
                            }
                        },
                        change_password: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.change_password(component, context).then(function(){
                                        component.password = component.new_password;
                                        component.snackbar.message = component.labelTerm('message > password resetSuccess');
                                        component.snackbar.display = true;
                                        setTimeout(function(){
                                            resolve();
                                        },3000)
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: {
                                    target: '#login.authenticate'
                                },
                                onError: {
                                    target: '#login.error'
                                }
                            }
                        }
                    }
                },
                authenticate: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            
                            comms.login(component, context).then(function(){
                                component.password = null;
                                if(component.remember_me!=true){
                                    component.email = null;
                                }
                                resolve();
                            },function(){
                                component.password = null;
                                reject();
                            })
                        }),
                        onDone: 'success',
                        onError: 'failed'
                    }
                },
                success: {
                    invoke: {
                        src: () => new Promise(()=>{
                            component.$emit('close');
                        })
                    }
                },
                failed: {},
                logout: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            comms.logout(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: 'login',
                        onError: 'error'
                    }
                },
                error: {},

            },
            on: {
                'authenticate': 'authenticate',
                'logout': 'logout',
                'login': 'login',
                'forgot_password': 'forgot_password.ready'
            }
        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();
    },
    data: function(){
        return {
            loginForm: false,
            valid_form_email_forgot_password: false,
            display: null,
            service: null,
            state: null,
            context: null,
            email: null,
            password: null,
            remember_me: null,
            verfication_code: null,
            verification_code_valid: false,
            config: {
                passwordType: 'password'
            },
            snackbar: {
                display: false,
                message: null
            },
            new_password: null,
            validPasswordForm: false,
            confirm_password: null,
            forgotPwdCounter: 1
        }
    },
    methods: {
        login: function(){
            if(this.$refs.loginForm.validate()){
                this.service.send('authenticate');
            }
        },
        logout: function(){
            this.service.send('logout');
        },
        togglePasswordType: function(){
            this.config.passwordType = this.config.passwordType=='password' ? 'text' : 'password';
        },
        handle_remember_me: function(){
            console.log('meow')
            if(this.remember_me===true){
                this.cookie.set('email',this.email);
            }else{
                this.cookie.clear('email');
            }
        },
        update_password: function(data){
            this.new_password = data;
            this.service.send('forgot_password.change_password')
        },
        send_code: function(){
            if (this.valid_form_email_forgot_password) {
                this.service.send('forgot_password.send_code');
            }
        }
    },
    computed: {
        view: function(){
            if(this.state.forgot_password){
                return 'forgot_password'
            }else{
                return 'login'
            }
        },
        logoImage: function(){
          return this.$store.getters.language=='fr' ? LogoFr : LogoEn;
        },        
    },
    watch: {
        show: function(){
            this.display = this.show===true;
        },
        verfication_code: function(after, before){ // eslint-disable-line no-unused-vars
            if (this.view==='forgot_password'){
                if (after && after.length==4){
                    this.service.send('forgot_password.validate_code');
                }
                else {               
                    this.forgotPwdCounter++;
                    this.verification_code_valid = false;
                }
            }
        }
    }
}
</script>

<style module>
.expandWidth {
    width: 320px;
}

.regularWidth {
    width: 300px;
}

.closeBtnWrapper {
    position: absolute !important;
    top: 15px;
    right: 15px;
}

.forgetLabel {
    margin-bottom: 0px !important;
    margin-left: 0.5%;
    color: black;
    font-size: 10pt;
}
</style>

<style>
#login-header .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 29px !important;
    height: auto !important;
    display: flex!important;
    align-items: center!important;
    padding-right: 2px;
    padding-left: 9px;
}

#login-header .v-text-field--enclosed .v-input__append-inner {
    margin-top: -3px;
}

#login-header .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{   
    padding-top: 0;
    padding-bottom: 0;
}

#login-header .v-select__selection--comma {
    margin-top: 0px;
    margin-bottom: 0px;
}

#login-header .v-text-field input {
    padding-top: 0;
    padding-bottom: 0;
}
</style>

<style lang="css" scoped>
/deep/ .v-btn {
    text-transform: initial;
}

/deep/ .v-dialog {
    box-shadow: none;
}

/deep/ .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 3px;
}

/deep/ .v-input--selection-controls .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before{
    background: transparent;
}
</style>